<script setup lang="ts">
import { useUserStore } from '@eggor/vue-shared'

definePage({
  name: 'Home',
})
const userStore = useUserStore()
const roles = userStore.roles
</script>

<template>
  <home-data-statistic class="w-100% mb-1rem" />
  <home-todo-list v-if="roles?.includes('commonAdmin') || roles?.includes('admin')" h-15rem />
  <home-chart />
</template>
