<script setup lang="ts">
import { addFetchErrorListener, clearErrorListeners, setDevMode, useAppStore } from '@eggor/vue-shared'
import { loadLanguageAsync } from './modules/i18n'
import useAuthRouterGuard from './composables/useAuthRouterGuard'

const router = useRouter()
const message = useMessage()
const loadingBar = useLoadingBar()
const { locale } = storeToRefs(useAppStore())
const { t } = useI18n()

useAuthRouterGuard({ name: 'login' }, (type) => {
  switch (type) {
    case 'Expired':
      return message.error(t('login.expired'))
    case 'FailedToFetch':
      return message.error(t('networkException'))
  }
})

watchPostEffect(() => {
  loadLanguageAsync(locale.value)
})

watchPostEffect((onInvalidate) => {
  const _ = router.beforeEach(() => {
    loadingBar.start()
  })
  onInvalidate(_)
})
watchPostEffect((onInvalidate) => {
  const _ = router.afterEach(() => {
    loadingBar.finish()
  })
  onInvalidate(_)
})
watchPostEffect((onInvalidate) => {
  setDevMode(import.meta.env.DEV)
  const _ = addFetchErrorListener((ctx) => {
    console.error('fetch error', { ...ctx })
    if (ctx.error?.name === 'AbortError')
      return
    if (ctx.response?.status === 500) {
      return message.error(t('networkException'))
    }
    message.error(ctx.error.message ?? ctx.error.toString(), {
      duration: 7000,
    })
  })
  onInvalidate(_)
})
if (import.meta.hot) {
  import.meta.hot.dispose(() => {
    clearErrorListeners()
  })
}
</script>

<template>
  <main font-sans>
    <RouterView />
  </main>
</template>
