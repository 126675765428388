<script setup lang="tsx">
import { AdminApi, parseMultilingual, useUserStore } from '@eggor/vue-shared'
import type { Result, UserModel } from '@eggor/vue-shared'
import type { FormInst, FormItemRule, FormRules, UploadFileInfo } from 'naive-ui'
import { NAvatar, NButton, NSwitch, NTime } from 'naive-ui'
import type { OnFinish } from 'naive-ui/es/upload/src/interface'
import Papa from 'papaparse'

definePage({ name: 'User' })
const userStore = useUserStore()
const { t } = useI18n()
const title = ref('')
const treeSelectData = ref()
const dialog = useDialog()
const router = useRouter()
const createTimeRange = ref<[number, number]>()
const route = useRoute('User')
const formRef = ref<FormInst | null>(null)
const showDialog = ref<boolean>(false)
const previewFileList = ref<UploadFileInfo[]>([])
const disabledUserName = ref<boolean>(false)
const headers = computed(() => ({ Authorization: `Bearer ${userStore.token}` }))

const showResetPasswordDialog = ref<boolean>(false)
const message = useMessage()
const resetUserPwd = ref({
  userId: '',
  password: '',
})
const queryParams = reactive({
  'userName': '',
  'nickName': '',
  'status': '',
  'deptId': Array.isArray(route.query.deptId) ? Number(route.query.deptId[0] ?? 0) : Number(route.query.deptId ?? 0),
  'dept.deptName': '',
  'startTime': '',
  'endTime': '',
})
const dialogForm = ref({
  userId: null,
  deptId: null,
  userName: '',
  nickName: '',
  password: '',
  phonenumber: '',
  email: '',
  sex: null,
  status: '0',
  remark: '',
  postIds: [],
  roleIds: [],
  biography: '',
  introduction: '',
  avatar: '',
  recommended: false,
})
const rules: FormRules = {
  avatar: {
    required: true,
    message: t('rules.message'),
    trigger: ['blur'],
  },
  nickName: {
    required: true,
    message: t('rules.message'),
    trigger: ['blur'],
  },
  userName: {
    required: true,
    message: t('rules.message'),
    trigger: ['blur'],
  },
  password: {
    required: true,
    message: t('rules.message'),
    trigger: ['blur'],
  },
  deptId: {
    required: true,
    trigger: ['blur'],
    validator: (_rule: FormItemRule, value: string) => {
      return new Promise<void>((resolve, reject) => {
        if (!value) {
          reject(t('rules.message'))
        }
        else {
          resolve()
        }
      })
    },
  },
  nftId: {
    required: true,
    message: t('rules.message'),
    trigger: ['blur'],
  },
  banner: {
    required: true,
    message: t('rules.message'),
    trigger: ['blur'],
  },
}
const changeRoleStatusForm = ref({
  userId: '',
  status: '0',
})
const { data: addResult, execute: addModel, isFetching: addIsFetching } = AdminApi.useAddModel('system/user', dialogForm)
const { data: updateResult, execute: updateModel, isFetching: updateIsFetching } = AdminApi.useUpdateModel('system/user', dialogForm)
const { data: deleteResult, execute: deleteModel, isFetching: deleteIsFetching } = AdminApi.useDeleteModel(computed(() => `system/user/${dialogForm.value.userId}`))
const { data: changeRoleStatusResult, isFetching: roleStatusChanging, execute: changeRoleStatus } = AdminApi.useModuleApi('system/user/changeStatus', {}, { immediate: false }).put(computed(() => (changeRoleStatusForm.value))).json<Result>()
const { data: userInfoNum, isFetching: userInfoNumIsFetching } = AdminApi.useModuleApi('eggor/statistics/getUserInfo', {}, { immediate: true }).get().json()
const { data: exportData, execute: exportModel, isFetching: exportIsFeching } = AdminApi.useModuleApi('system/user/exportToCsv', computed(() => ({ startTime: queryParams.startTime, endTime: queryParams.endTime })), { immediate: false }).get()
const { data: resetPwdResult, execute: resetPwd } = AdminApi.useModuleApi('system/user/resetPwd', {}, { immediate: false }).put(computed(() => (resetUserPwd.value))).json<Result>()
const { data: dictStatusResult } = AdminApi.useDictChildren({ dictType: 'sys_normal_disable' })
const { data: dictSexResult } = AdminApi.useDictChildren({ dictType: 'sys_user_sex' })
const { treeData, isFetching: treeDataIsFetching } = useUserDeptTreeData(dept => ({
  disabled: !!dept.children,
}))
function labelFomatter(children: any, infoNum: any) {
  children?.forEach((item: any) => {
    if (!item.children) {
      if (item.key === 103) {
        item.label = `${item.label}(${infoNum[1].totalCount})`
      }
      else if (item.key === 201) {
        item.label = `${item.label}(${infoNum[2].totalCount})`
      }
      else if (item.key === 202) {
        item.label = `${item.label}(${infoNum[3].totalCount})`
      }
      else if (item.key === 203) {
        item.label = `${item.label}(${infoNum[4].totalCount})`
      }
      else if (item.key === 204) {
        item.label = `${item.label}(${infoNum[5].totalCount})`
      }
    }
    else {
      labelFomatter(item.children, infoNum)
    }
  })
}

watch(exportData, () => {
  if (exportData.value) {
    const data = exportData.value
    const dataArr = Papa.parse(data as string)
    interface PapaType {
      data: []
    }
    (dataArr as never as PapaType).data.pop()
    const dataNew = (dataArr as never as PapaType).data.map((item: []) => {
      return item.map((i) => {
        return parseMultilingual(i)
      })
    })
    const text = dataNew.map(item => item.join(',')).join('\n')
    const blob = new Blob([text as BlobPart], { type: 'text/csv;charset=utf-8;' })
    // For other browsers
    const link = document.createElement('a')
    if (link.download !== undefined) {
      // Set up link attributes
      const url = URL.createObjectURL(blob)
      link.setAttribute('href', url)
      link.setAttribute('download', t('columns.system.user.userData'))

      // Append the link to the body
      document.body.appendChild(link)

      // Trigger the click event
      link.click()

      // Clean up
      document.body.removeChild(link)
      URL.revokeObjectURL(url)
    }
  }
})

watch([treeData, userInfoNum], () => {
  if (treeData.value && userInfoNum.value) {
    labelFomatter(treeData.value[0].children, userInfoNum.value.rows)
    treeSelectData.value = treeData.value
  }
})
// const { data: userAddResult, execute: optionsModel } = AdminApi.useNoPagination('system/user/')
function optionsGenerator(options: any, labelOption: string = 'dictLabel', valueOption: string = 'dictValue') {
  const _: any[] = []

  options?.forEach((item: any) => {
    const option = {
      label: parseMultilingual(item[labelOption]),
      value: item[valueOption],
      disabled: item?.status === '1',
    }
    _.push(option)
  },
  )
  return _
}
// const postOptions = computed(() => optionsGenerator(userAddResult.value?.posts, 'postName', 'postId'))
// const roleOptions = computed(() => optionsGenerator(userAddResult.value?.roles, 'roleName', 'roleId'))
const roleStatusOptions = computed(() => optionsGenerator(dictStatusResult?.value?.rows))
const sexOptions = computed(() => optionsGenerator(dictSexResult?.value?.rows))
watch([addResult, updateResult, changeRoleStatusResult, resetPwdResult], () => {
  const data = addResult.value || updateResult.value || changeRoleStatusResult.value
  if (data) {
    showDialog.value = false
    // eslint-disable-next-line ts/no-use-before-define
    reload()
  }
  if (resetPwdResult.value)
    showResetPasswordDialog.value = false
  if (deleteResult.value?.code === 200) {
    // eslint-disable-next-line ts/no-use-before-define
    reload()
    message.success(t('successfullyDeleted'))
  }
})
function disablePreviousDate(ts: number) {
  return ts > Date.now()
}
function reset() {
  dialogForm.value = {
    userId: null,
    deptId: null,
    userName: '',
    nickName: '',
    password: '',
    phonenumber: '',
    email: '',
    sex: null,
    status: '0',
    remark: '',
    postIds: [],
    roleIds: [],
    biography: '',
    introduction: '',
    avatar: '',
    recommended: false,
  }
  resetUserPwd.value = {
    userId: '',
    password: '',
  }
}
function handleAdd() {
  reset()
  disabledUserName.value = false
  // optionsModel()
  title.value = t('add')
  previewFileList.value = []
  showDialog.value = true
}
function handleUpdate(row: any) {
  title.value = t('modify')
  reset()
  disabledUserName.value = true
  // optionsModel()
  previewFileList.value = [{
    status: 'finished',
    id: row.id,
    name: `${row.name}.png`,
    url: row.avatar,
  }]
  dialogForm.value = row
  showDialog.value = true
}
const finishHandle: OnFinish = ({ event }) => {
  const response = JSON.parse((event?.target as XMLHttpRequest).response)
  dialogForm.value.avatar = response.url
  previewFileList.value = [{
    status: 'finished',
    id: response.code,
    name: response.originalFilename,
    url: response.url,
  }]
}
function handleExport() {
  exportModel()
}
function submitForm() {
  formRef.value?.validate((errors: any) => {
    if (errors)
      return
    if (dialogForm.value.userId)
      updateModel()
    else if (!dialogForm.value.userId)
      addModel()
  })
}
function resetPassword() {
  resetPwd()
}
function cancel() {
  showDialog.value = false
  showResetPasswordDialog.value = false
}
function handleDelete(row: any) {
  dialog.warning({
    title: t('deleteHint'),
    content: t('deleteReminder'),
    positiveText: t('confirm'),
    negativeText: t('cancel'),
    onPositiveClick: () => {
      dialogForm.value = row
      deleteModel()
    },
  })
}
function handleResetPwd(row: any) {
  title.value = t('columns.system.user.passwordReset')
  reset()
  resetUserPwd.value.userId = row.userId
  showResetPasswordDialog.value = true
}
function handleAuthRole(row: any) {
  router.replace({ name: 'UserAuth', params: { userId: row.userId } })
}
function formatTimestamp(timestamp: number) {
  const date = new Date(timestamp)

  const year = date.getFullYear()
  const month = padZero(date.getMonth() + 1)
  const day = padZero(date.getDate())
  const hours = padZero(date.getHours())
  const minutes = padZero(date.getMinutes())
  const seconds = padZero(date.getSeconds())

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
}
function padZero(value: any) {
  return String(value).padStart(2, '0')
}
function handleSelectTime(value: number[]) {
  queryParams.startTime = formatTimestamp(value[0])
  queryParams.endTime = formatTimestamp(value[1])
}
const _columns = computed(() => i18nColumns<UserModel>(t, 'system.user', ['userId', 'avatar', 'userName', 'nickName', 'deptName', 'status', 'createTime', 'actions'], (key, column) => {
  column.align = 'center'
  if (key === 'status') {
    return {
      ...column,
      render(rowData, _rowIndex) {
        return (
          <NSwitch
            value={!Number.parseInt(rowData.status)}
            onUpdateValue={(value) => {
              changeRoleStatusForm.value.userId = (rowData.userId).toString()
              changeRoleStatusForm.value.status = value ? '0' : '1'
              // eslint-disable-next-line ts/no-use-before-define
              changeRoleStatus().then(() => reload())
            }}
          />
        )
      },
    }
  }
  if (key === 'avatar') {
    return {
      ...column,
      render(rowData, _rowIndex) {
        return <NAvatar size="large" src={rowData.avatar} />
      },
    }
  }
  if (key === 'deptName') {
    return {
      ...column,
      render(rowData, _rowIndex) {
        return <span>{ parseMultilingual(rowData.dept?.deptName) }</span>
      },
    }
  }
  if (key === 'createTime') {
    return {
      ...column,
      render(rowData, _rowIndex) {
        return <NTime time={new Date(rowData.createTime)}></NTime>
      },
    }
  }
  if (key === 'actions') {
    return {
      ...column,
      render(rowData, _rowIndex) {
        if (rowData.dept.deptId === 103) {
          // return <span></span>
          return (
            <n-button-group v-if="rowData.userId !== 1">
              <NButton
                v-if="rowData.deptId===202 || rowData.deptId===203"
                circle
                type={rowData.recommended ? 'primary' : undefined}
                size="tiny"
                class="mb-1"
                onClick={async () => {
                  const json = {
                    recommended: !rowData.recommended,
                    userId: rowData.userId,
                    userName: rowData.userName,
                  }
                  dialogForm.value = JSON.parse(JSON.stringify(json))
                  await updateModel()
                  if (updateResult.value?.code === 200 && dialogForm.value.recommended === true) {
                    message.success(t('columns.system.user.tooltip.toppingSuccess'))
                  }
                  else if (updateResult.value?.code === 200 && dialogForm.value.recommended === false) {
                    message.success(t('columns.system.user.tooltip.toppingCancel'))
                  }
                  else {
                    message.error(updateResult.value?.msg === null ? t('networkException') : updateResult.value?.msg?.toString() ?? '')
                  }
                }}
                renderIcon={() => <span class="i-carbon-arrow-up" />}
              >
              </NButton>
              <NButton
                circle
                size="tiny"
                class="mb-1"
                onClick={() => {
                  handleUpdate(JSON.parse(JSON.stringify(rowData)))
                }}
                renderIcon={() => <span class="i-carbon-edit" />}
              >
              </NButton>
              <NButton
                size="tiny"
                onClick={() => {
                  handleDelete(rowData)
                }}
                renderIcon={() => <span class="i-carbon-trash-can" />}
              >
              </NButton>
              <NButton
                size="tiny"
                onClick={() => {
                  handleResetPwd(rowData)
                }}
                renderIcon={() => <span class="i-carbon-virtual-column-key" />}
              >
              </NButton>
              <NButton
                size="tiny"
                onClick={() => {
                  handleAuthRole(rowData)
                }}
                renderIcon={() => <span class="i-carbon-checkmark-outline" />}
              >
              </NButton>
            </n-button-group>
          )
        }
        else {
          return (
            <n-button-group v-if="rowData.userId !== 1">
              <NButton
                v-if="rowData.deptId===202 || rowData.deptId===203"
                circle
                type={rowData.recommended ? 'primary' : undefined}
                size="tiny"
                class="mb-1"
                onClick={async () => {
                  const json = {
                    recommended: !rowData.recommended,
                    userId: rowData.userId,
                    userName: rowData.userName,
                  }
                  dialogForm.value = JSON.parse(JSON.stringify(json))
                  await updateModel()
                  if (updateResult.value?.code === 200 && dialogForm.value.recommended === true) {
                    message.success(t('columns.system.user.tooltip.toppingSuccess'))
                  }
                  else if (updateResult.value?.code === 200 && dialogForm.value.recommended === false) {
                    message.success(t('columns.system.user.tooltip.toppingCancel'))
                  }
                  else {
                    message.error(updateResult.value?.msg === null ? t('networkException') : updateResult.value?.msg?.toString() ?? '')
                  }
                }}
                renderIcon={() => <span class="i-carbon-arrow-up" />}
              >
              </NButton>
              <NButton
                circle
                size="tiny"
                class="mb-1"
                onClick={() => {
                  handleUpdate(JSON.parse(JSON.stringify(rowData)))
                }}
                renderIcon={() => <span class="i-carbon-edit" />}
              >
              </NButton>
              <NButton
                size="tiny"
                onClick={() => {
                  handleDelete(rowData)
                }}
                renderIcon={() => <span class="i-carbon-trash-can" />}
              >
              </NButton>
              <NButton
                size="tiny"
                onClick={() => {
                  handleResetPwd(rowData)
                }}
                renderIcon={() => <span class="i-carbon-virtual-column-key" />}
              >
              </NButton>
              <NButton
                size="tiny"
                onClick={() => {
                  handleAuthRole(rowData)
                }}
                renderIcon={() => <span class="i-carbon-checkmark-outline" />}
              >
              </NButton>
            </n-button-group>
          )
        }
      },
    }
  }
  return column
}))

const { tableData, columns, pagination, selectHandle, reload, isFetching } = useModuleDataTable<UserModel>('system/user', _columns, queryParams)
function refresh() {
  reload()
}
function getKey(keys: number[]) {
  queryParams.deptId = keys[0]
}
</script>

<template>
  <n-grid :x-gap="12" :y-gap="8" :cols="5">
    <n-gi :span="1">
      <n-row>
        <n-input v-model:value="queryParams['dept.deptName']" clearable />
      </n-row>
      <n-spin :show="treeDataIsFetching || userInfoNumIsFetching" pr-8 shrink-0>
        <n-tree :data="treeSelectData" default-expand-all :default-selected-keys="[queryParams.deptId]" cancelable @update:selected-keys="getKey" />
      </n-spin>
      <n-row />
    </n-gi>
    <n-gi :span="4">
      <n-row>
        <n-form
          label-align="left" inline :model="queryParams" label-placement="left" :label-width="120"
          require-mark-placement="right-hanging" flex-wrap
        >
          <n-form-item :label="t('columns.system.user.userName')" path="userName">
            <n-input v-model:value="queryParams.userName" clearable />
          </n-form-item>
          <n-form-item :label="t('columns.system.user.nickName')" path="nickName">
            <n-input v-model:value="queryParams.nickName" clearable />
          </n-form-item>
          <n-form-item :label="t('columns.system.user.status')" path="status">
            <n-select v-model:value="queryParams.status" w-45 :options="roleStatusOptions" clearable />
          </n-form-item>
          <n-form-item :label="t('columns.system.user.createTime')">
            <n-date-picker v-model:value="createTimeRange" type="datetimerange" clearable :is-date-disabled="disablePreviousDate" format="yyyy-MM-dd HH:mm:ss" @update:value="handleSelectTime" />
          </n-form-item>
        </n-form>
      </n-row>
      <n-row style="margin-bottom: 1rem;">
        <n-grid style="justify-items: space-between;" :cols="24">
          <n-gi :span="12">
            <NButton type="primary" :disabled="addIsFetching || updateIsFetching || queryParams.deptId === 204" @click="handleAdd">
              <template #icon>
                <span i-carbon-add />
              </template>
              {{ t('add') }}
            </NButton>
            <NButton type="primary" class="ml-1.25rem" :disabled="exportIsFeching" @click="handleExport">
              <template #icon>
                <span i-carbon-download class="text-0.675rem" />
              </template>
              {{ t('export') }}
            </NButton>
          </n-gi>
          <n-gi :span="12">
            <NButton circle float-right @click="refresh">
              <template #icon>
                <span i-carbon-reset />
              </template>
            </NButton>
          </n-gi>
        </n-grid>
      </n-row>
      <n-row>
        <n-data-table
          v-model:page="pagination.page" v-model:page-size="pagination.pageSize" remote :data="tableData"
          :columns="columns" :pagination="pagination" :row-key="(row:UserModel) => row.userId" :loading="roleStatusChanging || addIsFetching || updateIsFetching || deleteIsFetching || isFetching"
          @update:checked-row-keys="selectHandle"
        />
      </n-row>
    </n-gi>
  </n-grid>
  <n-modal v-model:show="showDialog" :mask-closable="false" preset="dialog" :title="title" :show-icon="false" :style="{ width: '50%', maxWidth: '50rem', minWidth: '40rem' }">
    <n-form ref="formRef" :rules="rules" :model="dialogForm" label-placement="left" :label-width="90" label-align="left">
      <n-form-item :label="t('columns.system.user.avatar')" path="avatar">
        <n-upload
          v-model:file-list="previewFileList"
          action="/admin-api/eggor/uploadFile/uploadMinio"
          list-type="image-card"
          accept="image/png, image/jpeg"
          :max="1"
          :headers="headers"
          @finish="finishHandle"
        />
      </n-form-item>
      <n-grid :cols="24" :x-gap="24">
        <n-form-item-gi :label="t('columns.system.user.nickName')" path="nickName" :span="12">
          <n-input v-model:value="dialogForm.nickName" clearable />
        </n-form-item-gi>
        <n-form-item-gi :label="t('columns.system.user.userName')" path="userName" :span="12">
          <n-input v-model:value="dialogForm.userName" clearable :disabled="disabledUserName" />
        </n-form-item-gi>
      </n-grid>
      <n-grid :cols="24" :x-gap="24">
        <n-form-item-gi :label="t('columns.system.user.email')" path="email" :span="12">
          <n-input v-model:value="dialogForm.email" clearable />
        </n-form-item-gi>
        <n-form-item-gi :label="t('columns.system.user.deptName')" path="deptId" :span="12">
          <n-tree-select v-model:value="dialogForm.deptId" :options="treeData" clearable default-expand-all />
        </n-form-item-gi>
      </n-grid>
      <n-grid :cols="24" :x-gap="24">
        <n-form-item-gi :label="t('columns.system.user.sex')" path="sex" :span="12">
          <n-select v-model:value="dialogForm.sex" :options="sexOptions" clearable />
        </n-form-item-gi>
        <n-form-item-gi :label="t('columns.system.user.status')" path="status" :span="12">
          <n-radio-group v-model:value="dialogForm.status" name="radiogroup">
            <n-radio v-for="option in roleStatusOptions" :key="option.label" :value="option.value">
              {{ option.label }}
            </n-radio>
          </n-radio-group>
        </n-form-item-gi>
      </n-grid>
      <n-form-item :label="t('columns.system.user.biography')" path="biography">
        <n-input v-model:value="dialogForm.biography" type="textarea" maxlength="1000" show-count clearable />
      </n-form-item>
      <n-form-item :label="t('columns.system.user.introduction')" path="introduction">
        <n-input v-model:value="dialogForm.introduction" type="textarea" maxlength="1000" show-count clearable />
      </n-form-item>
      <n-form-item :label="t('remark')" path="remark" :span="12">
        <n-input v-model:value="dialogForm.remark" type="textarea" clearable />
      </n-form-item>
    </n-form>
    <template #action>
      <NButton ghost @click="cancel">
        {{ t('cancel') }}
      </NButton>
      <NButton :loading="addIsFetching || updateIsFetching" strong type="primary" @click="submitForm">
        {{ t('confirm') }}
      </NButton>
    </template>
  </n-modal>
  <n-modal v-model:show="showResetPasswordDialog" preset="dialog" :title="title" :show-icon="false" :style="{ width: '25%', maxWidth: '40rem', minWidth: '20rem' }">
    <p>{{ t('columns.system.user.tooltip.passwordTip', { userId: resetUserPwd.userId }) }}</p>
    <n-input v-model:value="resetUserPwd.password" show-password-on="mousedown" type="password" />
    <template #action>
      <NButton ghost color="#7ec9bc" @click="cancel">
        {{ t('cancel') }}
      </NButton>
      <NButton strong secondary color="#409eff" :disabled="addIsFetching || updateIsFetching" @click="resetPassword">
        {{ t('confirm') }}
      </NButton>
    </template>
  </n-modal>
</template>

<style scoped></style>
