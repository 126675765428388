<script setup lang="tsx">
import type { CommunityIdentityModel } from '@eggor/vue-shared'
import { AdminApi, parseMultilingual } from '@eggor/vue-shared'
import type { FormInst, FormRules } from 'naive-ui'
import { NAvatar, NButton, NButtonGroup, NTime } from 'naive-ui'
import DictTagClass from '~/components/core/DictTagClass.vue'

definePage({
  name: 'communityIdentify',
})
const { t } = useI18n()
const title = ref<string>('')
const message = useMessage()
const showPreview = ref<boolean>(false)
const showDialog = ref<boolean>(false)
const formRef = ref<FormInst | null>(null)
const queryParams = reactive({
  applyIdentity: '',
  applyCategory: '',
  communityName: '',
  bio: '',
  photo: '',
  productionUrl: '',
  record: '',
  reason: '',
  status: '',
  existStatus: '',
  userId: '',
})
const previewRow = ref({
  id: '',
  applyIdentity: '',
  applyCategory: '',
  communityName: '',
  bio: '',
  photo: '',
  productionUrl: '',
  record: '',
  reason: '',
  status: '',
  existStatus: '',
  userId: '',
})
const dialogForm = ref({
  id: undefined,
  reason: '',
  status: '1',
})
function reset() {
  dialogForm.value = {
    id: undefined,
    reason: '',
    status: '1',
  }
  previewRow.value = {
    id: '',
    applyIdentity: '',
    applyCategory: '',
    communityName: '',
    bio: '',
    photo: '',
    productionUrl: '',
    record: '',
    reason: '',
    status: '',
    existStatus: '',
    userId: '',
  }
}
const rules: FormRules = {
  status: {
    required: true,
    message: t('rules.message'),
    trigger: 'blur',
  },
}
function handleCheckOver(row: any) {
  reset()
  title.value = t('identify')
  dialogForm.value.id = row.id
  dialogForm.value.reason = row.reason ?? ''
  dialogForm.value.status = (row.status ?? '1').toString()
  showDialog.value = true
}
function handleView(row: any) {
  reset()
  title.value = t('preview')
  showPreview.value = true
  previewRow.value = row
}
const { data: checkOverResult, execute: checkOverModel, isFetching: checking } = AdminApi.useAddModel('eggor/communityIdentity/checkCommunityIdentity', dialogForm)
const { data: identityStatusResult } = AdminApi.useDictChildren({ dictType: 'sys_identity_status' })
// const { data: existStatusResult } = AdminApi.useDictChildren({ dictType: 'sys_community_existstatus' })
const { data: roleIdentityResult } = AdminApi.useDictChildren({ dictType: 'sys_role_identity' })

const roleIdentityOptions = computed(() => optionsGenerator(roleIdentityResult))

function optionsGenerator(dict: any) {
  const _: any[] = []
  dict.value?.rows.forEach((item: any) => {
    const option = {
      label: parseMultilingual(item.dictLabel),
      value: item.dictValue,
    }
    _.push(option)
  },
  )
  return _
}
// const existStatusOptions = computed(() => optionsGenerator(existStatusResult))
const identityStatusOptions = computed(() => {
  const _ = optionsGenerator(identityStatusResult)
  _.shift()
  return _
})
const isPassOptions = computed(() => {
  const _ = identityStatusOptions.value
  _.shift()
  return _
})

watch([checkOverResult], () => {
  const data = checkOverResult
  if (data) {
    message.success(t('successfullyModified'))
    showDialog.value = false
    // eslint-disable-next-line ts/no-use-before-define
    reload()
  }
})

function submitForm() {
  formRef.value?.validate((errors) => {
    if (errors)
      return
    checkOverModel()
  })
}
function cancel() {
  showDialog.value = false
  showPreview.value = false
}
const _columns = computed(() => i18nColumns<CommunityIdentityModel>(t, 'eggor.community.review', ['userId', 'applyIdentity', 'communityName', 'bio', 'photo', 'reason', 'status', 'createTime', 'actions'], (key, column) => {
  column.align = 'center'
  if (key === 'photo') {
    return {
      ...column,
      render(rowData, _rowIndex) {
        return <NAvatar size="large" src={rowData.photo} />
      },
    }
  }
  if (key === 'applyIdentity') {
    return {
      ...column,
      render(rowData, _rowIndex) {
        return <DictTagClass row={roleIdentityResult.value?.rows.find(item => item.dictValue === (rowData.applyIdentity.toString()))}></DictTagClass>
      },
    }
  }
  // if (key === 'existStatus') {
  //   return {
  //     ...column,
  //     render(rowData, _rowIndex) {
  //       return <DictTagClass row={existStatusResult.value?.rows.find(item => item.dictValue === (rowData.status.toString()))}></DictTagClass>
  //     },
  //   }
  // }
  if (key === 'status') {
    return {
      ...column,
      render(rowData, _rowIndex) {
        return <DictTagClass row={identityStatusResult.value?.rows.find(item => item.dictValue === (rowData.status.toString()))}></DictTagClass>
      },
    }
  }
  if (key === 'createTime') {
    return {
      ...column,
      render(rowData, _rowIndex) {
        return <NTime time={new Date(rowData.createTime)}></NTime>
      },
    }
  }
  if (key === 'actions') {
    return {
      ...column,
      render(rowData, _rowIndex) {
        return (
          <NButtonGroup>
            { ['0', '1'].includes(rowData.status.toString()) && (
              <NButton
                size="small"
                onClick={() => {
                  handleCheckOver(rowData)
                }}
                renderIcon={() => <span class="i-carbon-star-review" />}
              />
            ) }
            <NButton
              size="small"
              onClick={() => {
                handleView(rowData)
              }}
              renderIcon={() => <span class="i-carbon-view" />}
            />
          </NButtonGroup>
        )
      },
    }
  }
  return column
}))
const { tableData, columns, pagination, isFetching, selectHandle, rowKey, reload } = useModuleDataTable<CommunityIdentityModel>('eggor/communityIdentity', _columns, queryParams)
function refresh() {
  reload()
}
</script>

<template>
  <n-form
    label-align="left"
    inline
    :model="queryParams"
    label-placement="left"
    :label-width="140"
    require-mark-placement="right-hanging"
    flex-wrap
  >
    <n-form-item :label="t('columns.eggor.community.review.userId')" path="userId">
      <n-input v-model:value="queryParams.userId" clearable />
    </n-form-item>
    <n-form-item :label="t('columns.eggor.community.review.applyIdentity')" path="applyIdentity">
      <n-select v-model:value="queryParams.applyIdentity" :options="roleIdentityOptions" clearable w-48 />
    </n-form-item>
    <n-form-item :label="t('columns.eggor.community.review.communityName')" path="communityName">
      <n-input v-model:value="queryParams.communityName" clearable />
    </n-form-item>
    <!-- <n-form-item :label="t('columns.eggor.community.review.existStatus')" path="existStatus">
      <n-select v-model:value="queryParams.existStatus" :options="existStatusOptions" clearable w-48 />
    </n-form-item> -->
    <n-form-item :label="t('columns.eggor.community.review.status')" path="status">
      <n-select v-model:value="queryParams.status" :options="identityStatusOptions" clearable w-48 />
    </n-form-item>
  </n-form>
  <div mb-5>
    <NButton circle style="float: right;" @click="refresh">
      <template #icon>
        <span i-carbon-reset />
      </template>
    </NButton>
  </div>
  <n-data-table
    v-model:page="pagination.page"
    v-model:page-size="pagination.pageSize"
    remote
    :data="tableData"
    :columns="columns"
    :pagination="pagination"
    :row-key="rowKey"
    :loading="isFetching || checking"
    @update:checked-row-keys="selectHandle"
  />
  <n-modal v-model:show="showDialog" :mask-closable="false" preset="dialog" :title="title" :show-icon="false" :style="{ width: '30%', maxWidth: '40rem', minWidth: '25rem' }">
    <n-form
      ref="formRef"
      :model="dialogForm"
      label-placement="left"
      :label-width="120"
      label-align="left"
      :rules="rules"
    >
      <n-form-item :label="t('columns.eggor.community.review.status')" path="status">
        <n-radio-group v-model:value="dialogForm.status" name="radiogroup">
          <n-radio v-for="option in isPassOptions" :key="option.label" :value="option.value">
            {{ option.label }}
          </n-radio>
        </n-radio-group>
      </n-form-item>
      <n-form-item v-show="dialogForm.status === '2'" :label="t('columns.eggor.community.review.reason')" path="reason">
        <n-input v-model:value="dialogForm.reason" clearable />
      </n-form-item>
    </n-form>
    <template #action>
      <NButton :disabled="checking" ghost @click="cancel">
        {{ t('cancel') }}
      </NButton>
      <NButton :loading="checking" strong type="primary" :disabled="checking" @click="submitForm">
        {{ t('confirm') }}
      </NButton>
    </template>
  </n-modal>
  <n-modal v-model:show="showPreview" preset="dialog" :title="title" :show-icon="false" :style="{ width: '45%', maxWidth: '50rem', minWidth: '40rem' }">
    <n-form :model="previewRow" label-placement="left" :label-width="120" label-align="left">
      <n-grid :cols="24" :x-gap="24" class="h-6rem">
        <n-form-item-gi :label="t('columns.eggor.community.review.photo')" :span="12" label-style="opacity: 70%">
          <NAvatar class="h-5rem w-5rem" :src="previewRow.photo" size="large" circle />
        </n-form-item-gi>
        <n-form-item-gi :label="t('columns.eggor.community.review.userId')" :span="12" label-style="opacity: 70%">
          {{ previewRow.userId }}
        </n-form-item-gi>
      </n-grid>
      <n-grid :cols="24" :x-gap="24">
        <n-form-item-gi :label="t('columns.eggor.community.review.applyIdentity')" :span="12" label-style="opacity: 70%">
          <DictTagClass :row="roleIdentityResult?.rows.find(item => item.dictValue === (previewRow.applyIdentity ?? '').toString())" />
        </n-form-item-gi>
        <n-form-item-gi :label="t('columns.eggor.community.review.applyCategory')" :span="12" label-style="opacity: 70%">
          {{ previewRow.applyCategory }}
        </n-form-item-gi>
      </n-grid>
      <n-grid :cols="24" :x-gap="24">
        <n-form-item-gi :label="t('columns.eggor.community.review.communityName')" :span="12" label-style="opacity: 70%">
          {{ previewRow.communityName }}
        </n-form-item-gi>
        <n-form-item-gi :label="t('columns.eggor.community.review.bio')" :span="12" label-style="opacity: 70%">
          {{ previewRow.bio }}
        </n-form-item-gi>
      </n-grid>
      <n-grid :cols="24" :x-gap="24">
        <n-form-item-gi :label="t('columns.eggor.community.review.record')" :span="12" label-style="opacity: 70%">
          {{ previewRow.record }}
        </n-form-item-gi>
        <n-form-item-gi :label="t('columns.eggor.community.review.reason')" :span="12" label-style="opacity: 70%">
          {{ previewRow.reason }}
        </n-form-item-gi>
      </n-grid>
      <n-form-item :label="t('columns.eggor.community.review.productionUrl')" label-style="opacity: 70%">
        <div class="col gap-.5rem">
          <a v-for="file in previewRow.productionUrl" :key="file" :href="file" target="_blank">{{ file }}</a>
        </div>
      </n-form-item>
    </n-form>
    <template #action>
      <NButton ghost @click="cancel">
        {{ t('close') }}
      </NButton>
    </template>
  </n-modal>
</template>

<style scoped>

</style>
