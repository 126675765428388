import _definePage_default_0 from '/tmp/admin/src/pages/index.vue?definePage&vue'
import { _mergeRouteRecord } from 'unplugin-vue-router/runtime'
import _definePage_default_2 from '/tmp/admin/src/pages/community/comment.vue?definePage&vue'
import _definePage_default_3 from '/tmp/admin/src/pages/community/comment-children/[targetId].vue?definePage&vue'
import _definePage_default_4 from '/tmp/admin/src/pages/community/communityReview.vue?definePage&vue'
import _definePage_default_5 from '/tmp/admin/src/pages/community/like-user/[id].vue?definePage&vue'
import _definePage_default_6 from '/tmp/admin/src/pages/community/post.vue?definePage&vue'
import _definePage_default_7 from '/tmp/admin/src/pages/community/report.vue?definePage&vue'
import _definePage_default_8 from '/tmp/admin/src/pages/eggor/index.vue?definePage&vue'
import _definePage_default_9 from '/tmp/admin/src/pages/system/data/dict.vue?definePage&vue'
import _definePage_default_10 from '/tmp/admin/src/pages/system/data/dict-data/[dictType].vue?definePage&vue'
import _definePage_default_11 from '/tmp/admin/src/pages/user/identity.vue?definePage&vue'
import _definePage_default_12 from '/tmp/admin/src/pages/user/role.vue?definePage&vue'
import _definePage_default_13 from '/tmp/admin/src/pages/user/role-auth/user/[roleId].vue?definePage&vue'
import _definePage_default_14 from '/tmp/admin/src/pages/user/user.vue?definePage&vue'
import _definePage_default_15 from '/tmp/admin/src/pages/user/user-auth/role/[userId].vue?definePage&vue'

export const routes = [
  _mergeRouteRecord(
  {
    path: '/',
    name: 'Home',
    component: () => import('/tmp/admin/src/pages/index.vue'),
    /* no children */
  },
  _definePage_default_0
  ),
  {
    path: '/:404(.*)',
    name: '/[...404]',
    component: () => import('/tmp/admin/src/pages/[...404].vue'),
    /* no children */
  },
  {
    path: '/community',
    /* internal name: '/community' */
    /* no component */
    children: [
      {
        path: 'category',
        name: '/community/category',
        component: () => import('/tmp/admin/src/pages/community/category.vue'),
        /* no children */
      },
  _mergeRouteRecord(
      {
        path: 'comment',
        name: 'Comment',
        component: () => import('/tmp/admin/src/pages/community/comment.vue'),
        /* no children */
      },
  _definePage_default_2
  ),
      {
        path: 'comment-children',
        /* internal name: '/community/comment-children' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: ':targetId',
            name: 'commentChildren',
            component: () => import('/tmp/admin/src/pages/community/comment-children/[targetId].vue'),
            /* no children */
          },
  _definePage_default_3
  )
        ],
      },
      {
        path: 'community',
        name: '/community/community',
        component: () => import('/tmp/admin/src/pages/community/community.vue'),
        /* no children */
      },
  _mergeRouteRecord(
      {
        path: 'communityReview',
        name: 'communityIdentify',
        component: () => import('/tmp/admin/src/pages/community/communityReview.vue'),
        /* no children */
      },
  _definePage_default_4
  ),
      {
        path: 'like-user',
        /* internal name: '/community/like-user' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: ':id',
            name: 'LikeUser',
            component: () => import('/tmp/admin/src/pages/community/like-user/[id].vue'),
            /* no children */
          },
  _definePage_default_5
  )
        ],
      },
  _mergeRouteRecord(
      {
        path: 'post',
        name: 'Post',
        component: () => import('/tmp/admin/src/pages/community/post.vue'),
        /* no children */
      },
  _definePage_default_6
  ),
  _mergeRouteRecord(
      {
        path: 'report',
        name: 'reportIdentify',
        component: () => import('/tmp/admin/src/pages/community/report.vue'),
        /* no children */
      },
  _definePage_default_7
  )
    ],
  },
  {
    path: '/eggor',
    /* internal name: '/eggor' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: '/eggor/',
        component: () => import('/tmp/admin/src/pages/eggor/index.vue'),
        /* no children */
      },
  _definePage_default_8
  ),
      {
        path: 'advertisement',
        name: '/eggor/advertisement',
        component: () => import('/tmp/admin/src/pages/eggor/advertisement.vue'),
        /* no children */
      },
      {
        path: 'post',
        name: '/eggor/post',
        component: () => import('/tmp/admin/src/pages/eggor/post.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('/tmp/admin/src/pages/login.vue'),
    /* no children */
    meta: {
      "layout": "empty"
    }
  },
  {
    path: '/operate',
    /* internal name: '/operate' */
    /* no component */
    children: [
      {
        path: 'activity',
        name: '/operate/activity',
        component: () => import('/tmp/admin/src/pages/operate/activity.vue'),
        /* no children */
      },
      {
        path: 'banner',
        name: '/operate/banner',
        component: () => import('/tmp/admin/src/pages/operate/banner.vue'),
        /* no children */
      },
      {
        path: 'exploreBanner',
        name: '/operate/exploreBanner',
        component: () => import('/tmp/admin/src/pages/operate/exploreBanner.vue'),
        /* no children */
      },
      {
        path: 'galleryBanner',
        name: '/operate/galleryBanner',
        component: () => import('/tmp/admin/src/pages/operate/galleryBanner.vue'),
        /* no children */
      },
      {
        path: 'hotCommunity',
        name: '/operate/hotCommunity',
        component: () => import('/tmp/admin/src/pages/operate/hotCommunity.vue'),
        /* no children */
      },
      {
        path: 'notice',
        name: '/operate/notice',
        component: () => import('/tmp/admin/src/pages/operate/notice.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/system',
    /* internal name: '/system' */
    /* no component */
    children: [
      {
        path: 'data',
        /* internal name: '/system/data' */
        /* no component */
        children: [
          {
            path: 'config',
            name: '/system/data/config',
            component: () => import('/tmp/admin/src/pages/system/data/config.vue'),
            /* no children */
          },
          {
            path: 'dept',
            name: '/system/data/dept',
            component: () => import('/tmp/admin/src/pages/system/data/dept.vue'),
            /* no children */
          },
  _mergeRouteRecord(
          {
            path: 'dict',
            name: 'Dict',
            component: () => import('/tmp/admin/src/pages/system/data/dict.vue'),
            /* no children */
          },
  _definePage_default_9
  ),
          {
            path: 'dict-data',
            /* internal name: '/system/data/dict-data' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: ':dictType',
                name: 'DictType',
                component: () => import('/tmp/admin/src/pages/system/data/dict-data/[dictType].vue'),
                /* no children */
              },
  _definePage_default_10
  )
            ],
          },
          {
            path: 'menu',
            name: '/system/data/menu',
            component: () => import('/tmp/admin/src/pages/system/data/menu.vue'),
            /* no children */
          },
          {
            path: 'uploadFile',
            name: '/system/data/uploadFile',
            component: () => import('/tmp/admin/src/pages/system/data/uploadFile.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'log',
        /* internal name: '/system/log' */
        /* no component */
        children: [
          {
            path: 'logininfor',
            name: '/system/log/logininfor',
            component: () => import('/tmp/admin/src/pages/system/log/logininfor.vue'),
            /* no children */
          },
          {
            path: 'operlog',
            name: '/system/log/operlog',
            component: () => import('/tmp/admin/src/pages/system/log/operlog.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'monitor',
        /* internal name: '/system/monitor' */
        /* no component */
        children: [
          {
            path: 'job',
            name: '/system/monitor/job',
            component: () => import('/tmp/admin/src/pages/system/monitor/job.vue'),
            /* no children */
          },
          {
            path: 'job-log',
            /* internal name: '/system/monitor/job-log' */
            /* no component */
            children: [
              {
                path: '',
                /* internal name: '/system/monitor/job-log/' */
                /* no component */
                children: [
                  {
                    path: ':jobId',
                    name: '/system/monitor/job-log//[jobId]',
                    component: () => import('/tmp/admin/src/pages/system/monitor/job-log/index/[jobId].vue'),
                    /* no children */
                  }
                ],
              }
            ],
          },
          {
            path: 'online',
            name: '/system/monitor/online',
            component: () => import('/tmp/admin/src/pages/system/monitor/online.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'notice',
        name: '/system/notice',
        component: () => import('/tmp/admin/src/pages/system/notice.vue'),
        /* no children */
      },
      {
        path: 'tool',
        /* internal name: '/system/tool' */
        /* no component */
        children: [
          {
            path: 'gen',
            name: '/system/tool/gen',
            component: () => import('/tmp/admin/src/pages/system/tool/gen.vue'),
            /* no children */
          },
          {
            path: 'swagger',
            name: '/system/tool/swagger',
            component: () => import('/tmp/admin/src/pages/system/tool/swagger.vue'),
            /* no children */
          }
        ],
      }
    ],
  },
  {
    path: '/user',
    /* internal name: '/user' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: 'identity',
        name: 'roleIdentify',
        component: () => import('/tmp/admin/src/pages/user/identity.vue'),
        /* no children */
      },
  _definePage_default_11
  ),
      {
        path: 'InformationCollection',
        name: '/user/InformationCollection',
        component: () => import('/tmp/admin/src/pages/user/InformationCollection.vue'),
        /* no children */
      },
      {
        path: 'invitationRecord',
        name: '/user/invitationRecord',
        component: () => import('/tmp/admin/src/pages/user/invitationRecord.vue'),
        /* no children */
      },
  _mergeRouteRecord(
      {
        path: 'role',
        name: 'Role',
        component: () => import('/tmp/admin/src/pages/user/role.vue'),
        /* no children */
      },
  _definePage_default_12
  ),
      {
        path: 'role-auth',
        /* internal name: '/user/role-auth' */
        /* no component */
        children: [
          {
            path: 'user',
            /* internal name: '/user/role-auth/user' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: ':roleId',
                name: 'RoleAuth',
                component: () => import('/tmp/admin/src/pages/user/role-auth/user/[roleId].vue'),
                /* no children */
              },
  _definePage_default_13
  )
            ],
          }
        ],
      },
  _mergeRouteRecord(
      {
        path: 'user',
        name: 'User',
        component: () => import('/tmp/admin/src/pages/user/user.vue'),
        /* no children */
      },
  _definePage_default_14
  ),
      {
        path: 'user-auth',
        /* internal name: '/user/user-auth' */
        /* no component */
        children: [
          {
            path: 'role',
            /* internal name: '/user/user-auth/role' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: ':userId',
                name: 'UserAuth',
                component: () => import('/tmp/admin/src/pages/user/user-auth/role/[userId].vue'),
                /* no children */
              },
  _definePage_default_15
  )
            ],
          }
        ],
      }
    ],
  }
]
