<script setup lang="tsx">
import { AdminApi } from '@eggor/vue-shared'
import type { Result, UserModel, roleAuthResponse } from '@eggor/vue-shared'
import type { DataTableRowKey, PaginationProps } from 'naive-ui'
import { NButton, NTime } from 'naive-ui'
import type { TableColumn } from 'naive-ui/es/data-table/src/interface'
import DictTagClass from '~/components/core/DictTagClass.vue'

definePage({
  name: 'RoleAuth',
})
const route = useRoute()
const tableData = ref()
const message = useMessage()
const dialog = useDialog()
const userId = ref()
const router = useRouter()
const roleId = computed(() => (route.params as any).roleId)
const { t } = useI18n()

const pagination = reactive<PaginationProps>({
  page: 1,
  pageSize: 10,
  pageCount: 0,
  itemCount: 0,
  showSizePicker: true,
  pageSlot: 5,
  pageSizes: [10, 20, 30, 50],
})

const queryParams = reactive({
  userName: '',
  phonenumber: '',
})

const { data: statusResult } = AdminApi.useDictChildren({ dictType: 'sys_normal_disable' })
const { data: cancelAuthUserResponse, execute: cancelAuth } = AdminApi.useUpdateModel('/system/role/authUser/cancel', computed(() => ({ roleId: roleId.value, userId: userId.value })))
const _columns = computed(() => i18nColumns<UserModel>(t, 'system.user', ['userName', 'nickName', 'email', 'phonenumber', 'status', 'createTime', 'actions'], (key, column) => {
  column.align = 'center'
  if (key === 'status') {
    return {
      ...column,
      render(rowData, _rowIndex) {
        return <DictTagClass row={statusResult.value?.rows.find(item => item.dictValue === rowData.status)}></DictTagClass>
      },
    }
  }
  if (key === 'createTime') {
    return {
      ...column,
      render(rowData, _rowIndex) {
        return <NTime time={new Date(rowData.createTime)}></NTime>
      },
    }
  }
  if (key === 'actions') {
    return {
      ...column,
      render(rowData, _rowIndex) {
        return (
          <n-button-group v-if="rowData.userId !== 1">
            <NButton
              circle
              size="tiny"
              class="mb-1"
              onClick={() => {
                handleAuthorizationCancel(rowData)
              }}
              renderIcon={() => <span class="i-carbon-close-outline" />}
            >
            </NButton>
          </n-button-group>
        )
      },
    }
  }
  return column
}))
const columns: TableColumn[] = [{ type: 'selection' }, ...(toValue(_columns.value) as any)]
const checkedRowKeysRef = ref<DataTableRowKey[]>([])
function selectHandle(rowKeys: DataTableRowKey[]) {
  checkedRowKeysRef.value = rowKeys
}
function handleAuthorizationCancel(row: UserModel) {
  dialog.warning({
    title: t('columns.system.roleAuth.title'),
    content: t('columns.system.roleAuth.content'),
    positiveText: t('confirm'),
    negativeText: t('cancel'),
    onPositiveClick: () => {
      userId.value = row.userId
      cancelAuth()
    },
  })
}
const { data, execute } = AdminApi.useModuleApi('system/role/authUser/allocatedList', computed(() => ({ roleId: roleId.value, pageSize: pagination.pageSize, pageNum: pagination.page, ...toValue(queryParams) })), { immediate: true }).get().json<Result & roleAuthResponse>()
watch(data, () => {
  if (data.value) {
    tableData.value = data.value?.rows || []
    pagination.pageCount = Math.ceil(data.value.total / (pagination.pageSize ?? 10))
    pagination.itemCount = data.value.total
  }
})
watch(cancelAuthUserResponse, () => {
  if (cancelAuthUserResponse.value) {
    message.success(t('columns.system.roleAuth.cancelAuthRoleSuccess'))
    execute()
  }
})
watch([pagination, queryParams], () => {
  execute()
})
function handleClose() {
  router.replace({ name: 'Role' })
}
function refresh() {
  execute()
}
</script>

<template>
  <n-form
    inline :model="queryParams" label-align="left" label-placement="left" :label-width="140"
    require-mark-placement="right-hanging" flex-wrap
  >
    <n-form-item :label="t('columns.system.user.userName')" path="userName">
      <n-input v-model:value="queryParams.userName" />
    </n-form-item>
    <n-form-item :label="t('columns.system.user.phonenumber')" path="phonenumber">
      <n-input v-model:value="queryParams.phonenumber" />
    </n-form-item>
  </n-form>
  <div mb-5>
    <NButton type="default" @click="handleClose">
      <template #icon>
        <span i-carbon-close />
      </template>{{ t('close') }}
    </NButton>
    <NButton circle style="float: right;" @click="refresh">
      <template #icon>
        <span i-carbon-reset />
      </template>
    </NButton>
  </div>
  <n-data-table
    v-model:page="pagination.page" v-model:page-size="pagination.pageSize" remote :data="tableData"
    :columns="columns" :pagination="pagination" :row-key="(row:UserModel) => row.userId"
    @update:checked-row-keys="selectHandle"
  />
</template>

<style scoped>

</style>
