<!-- eslint-disable ts/no-use-before-define -->
<script setup lang="tsx">
import { AdminApi, deltaToSummary } from '@eggor/vue-shared'
import type { PostModel } from '@eggor/vue-shared'
import type { FormInst, FormRules } from 'naive-ui'
import { NButton, NButtonGroup, NTag, NTime } from 'naive-ui'

definePage({
  name: 'Post',
})
const { t } = useI18n()
const title = ref<string>('')
const router = useRouter()
const message = useMessage()
const dialog = useDialog()
const showDetail = ref<boolean>(false)
const showDialog = ref<boolean>(false)
const formRef = ref<FormInst | null>(null)
const dialogForm = ref({
  id: null,
  userName: null,
  viewNum: null,
  replyNum: null,
  likedNum: null,
  content: '',
  photo: [],
  video: [],
  audio: [],
  createTime: null,
  updateTime: null,
})
function reset() {
  dialogForm.value = {
    id: null,
    userName: null,
    viewNum: null,
    replyNum: null,
    likedNum: null,
    content: '',
    photo: [],
    video: [],
    audio: [],
    createTime: null,
    updateTime: null,
  }
}
const queryParams = reactive({ userName: '', content: '', type: '', communityName: '' })
const { data: updateResult, execute: updateModel, isFetching: updateIsFetching } = AdminApi.useUpdateModel('eggor/post', dialogForm)
const { data: deleteResult, execute: deleteModel, isFetching: deleteIsFetching } = AdminApi.useDeleteModel(computed(() => `eggor/post/${dialogForm.value.id}`))
const rules: FormRules = {

  userName: {
    required: true,
    message: t('rules.message'),
    trigger: ['blur'],
  },
  content: {
    required: true,
    message: t('rules.message'),
    trigger: 'blur',
  },
}
function submitForm() {
  formRef.value?.validate((errors) => {
    if (errors)
      return
    updateModel()
  })
}
function cancel() {
  showDialog.value = false
}
function handleDelete(row: any) {
  reset()
  dialog.warning({
    title: t('deleteHint'),
    content: t('deleteReminder'),
    positiveText: t('confirm'),
    negativeText: t('cancel'),
    onPositiveClick: () => {
      dialogForm.value = row
      deleteModel()
    },
  })
}
watch([updateResult, deleteResult], () => {
  if (updateResult.value) {
    showDialog.value = false
    reload()
  }
  if (deleteResult.value?.code === 200) {
    reload()
    message.success(t('successfullyDeleted'))
  }
})

function changeShowContent(data: any) {
  reset()
  dialogForm.value = data
  dialogForm.value.content = deltaToSummary(data.content)
  showDetail.value = true
}
function showComment(rowData: PostModel) {
  if (rowData.replyNum !== 0) {
    router.push({ name: 'Comment', query: { postId: rowData.id } })
  }
  else {
    message.info(t('columns.eggor.post.isHaveComment'))
  }
}
function showLike(rowData: PostModel) {
  if (rowData.likedNum !== 0) {
    router.push({ name: 'LikeUser', params: { id: rowData.id }, query: { type: 'post' } })
  }
  else {
    message.info(t('columns.eggor.post.isHaveliked'))
  }
}
const _columns = computed(() => i18nColumns<PostModel>(t, 'eggor.post', ['id', 'userName', 'content', 'viewNum', 'replyNum', 'likedNum', 'createTime', 'actions'], (key, column) => {
  column.align = 'center'
  if (key === 'content') {
    return {
      ...column,
      width: '25%',
      render(rowData, _rowIndex) {
        return (
          <div
            class="h-4.1875rem flex justify-center items-center"
            onClick={() => { changeShowContent(rowData) }}

          >
            <p
              class="line-clamp-3"
            >
              {deltaToSummary(rowData.content)}
            </p>
          </div>
        )
      },
    }
  }
  if (key === 'id') {
    return {
      width: '5rem',
      ...column,
    }
  }
  if (key === 'userName') {
    return {
      width: '20rem',
      ...column,
      render(rowData, _rowIndex) {
        return (
          <p>
            <NTag type="info">{rowData.user.nickName}</NTag>
            <br />
            { rowData.user.userName }
          </p>
        )
      },
    }
  }
  if (key === 'replyNum') {
    return {
      ...column,
      render(rowData, _rowIndex) {
        return (
          <div
            class="h-4.1875rem flex justify-center items-center"
            onClick={() => {
              showComment(JSON.parse(JSON.stringify(rowData)))
            }}
          >
            {rowData.replyNum}
          </div>
        )
      },
    }
  }
  if (key === 'likedNum') {
    return {
      ...column,
      render(rowData, _rowIndex) {
        return (
          <div
            class="h-4.1875rem flex justify-center items-center"
            onClick={() => {
              showLike(JSON.parse(JSON.stringify(rowData)))
            }}
          >
            {rowData.likedNum}
          </div>
        )
      },
    }
  }
  if (key === 'createTime') {
    return {
      ...column,
      render(rowData, _rowIndex) {
        return <NTime time={new Date(rowData.createTime)}></NTime>
      },
    }
  }
  if (key === 'actions') {
    return {
      ...column,
      render(rowData, _rowIndex) {
        return (
          <NButtonGroup size="small">
            <NButton
              secondary
              round
              type="error"
              onClick={() => {
                handleDelete(rowData)
              }}
            >
              {t('delete')}
            </NButton>
          </NButtonGroup>
        )
      },
    }
  }
  return column
}))
const { tableData, columns, pagination, selectHandle, rowKey, reload, isFetching } = useModuleDataTable<PostModel>('eggor/post', _columns, queryParams)
function refresh() {
  reload()
}
</script>

<template>
  <n-form
    inline :model="queryParams" label-align="left" label-placement="left" :label-width="140"
    require-mark-placement="right-hanging" flex-wrap
  >
    <n-form-item :label="t('columns.eggor.post.userName')" path="userName">
      <n-input v-model:value="queryParams.userName" clearable />
    </n-form-item>
    <n-form-item :label="t('columns.eggor.post.content')" path="content">
      <n-input v-model:value="queryParams.content" clearable />
    </n-form-item>
    <n-form-item :label="t('columns.eggor.post.communityName')" path="communityName">
      <n-input v-model:value="queryParams.communityName" clearable />
    </n-form-item>
  </n-form>
  <div mb-5>
    <NButton circle style="float: right;" @click="refresh">
      <template #icon>
        <span i-carbon-reset />
      </template>
    </NButton>
  </div>
  <n-data-table
    v-model:page="pagination.page" v-model:page-size="pagination.pageSize" remote :data="tableData" :loading="updateIsFetching || isFetching || deleteIsFetching"
    :columns="columns" :pagination="pagination" :row-key="rowKey" @update:checked-row-keys="selectHandle"
  />
  <n-modal v-model:show="showDialog" :mask-closable="false" preset="dialog" :title="title" :show-icon="false">
    <n-form
      ref="formRef"
      :model="dialogForm"
      label-placement="left"
      :label-width="110"
      label-align="left"
      :rules="rules"
    >
      <n-form-item :label="t('columns.eggor.post.userName')" path="userName">
        <n-input v-model:value="dialogForm.userName" clearable />
      </n-form-item>
      <n-form-item :label="t('columns.eggor.post.content')" path="content">
        <n-input v-model:value="dialogForm.content" clearable />
      </n-form-item>
    </n-form>
    <template #action>
      <NButton ghost @click="cancel">
        {{ t('cancel') }}
      </NButton>
      <NButton strong type="primary" :disabled=" updateIsFetching" @click="submitForm">
        {{ t('confirm') }}
      </NButton>
    </template>
  </n-modal>
  <n-modal v-model:show="showDetail" :mask-closable="false" preset="card" :title="title" :show-icon="false" style="width:auto; min-width: 30%; max-width: 70%;">
    <div v-if="dialogForm.content">
      {{ dialogForm.content }}
    </div>
    <div v-if="dialogForm.video.length > 0" class="w-auto">
      <video v-for="(i, v) in dialogForm.video" :key="v" :src="i" controls class="h-25rem w-auto" />
    </div>
    <div v-if="dialogForm.audio.length > 0">
      <audio v-for="(i, v) in dialogForm.audio" :key="v" :src="i" controls />
    </div>
    <div v-if="dialogForm.photo.length === 1" class="row gap-4 mt-1.25rem max-h-24.375rem flex-wrap w-fit max-w-500px">
      <img loading="lazy" :src="dialogForm.photo[0]" class="rounded object-contain max-h-24.375rem  max-w-full">
    </div>
    <div v-else-if="dialogForm.photo.length === 2" class="row gap-1 mt-1.25rem rounded overflow-hidden w-fit">
      <img v-for="photo in dialogForm.photo" :key="photo" loading="lazy" :src="photo" class=" object-cover h-24.375rem grow object-center">
    </div>
    <div v-else-if="dialogForm.photo.length === 3" class="row gap-1 mt-1.25rem h-24.375rem  rounded overflow-hidden w-fit">
      <img loading="lazy" :src="dialogForm.photo[0]" class=" object-cover w-15.625rem max-w-50% object-center  h-full">
      <div class="col gap-1 max-w-50%">
        <img loading="lazy" :src="dialogForm.photo[1]" class=" object-cover h-12.5rem w-15.625rem max-w-full object-center ">
        <img loading="lazy" :src="dialogForm.photo[2]" class=" object-cover h-12.5rem w-15.625rem max-w-full object-center ">
      </div>
    </div>
    <div v-else-if="dialogForm.photo.length >= 4" class="grid  grid-cols-2 gap-1 mt-1.25rem rounded overflow-hidden w-fit">
      <img loading="lazy" :src="dialogForm.photo[0]" class=" object-cover h-12.5rem w-15.625rem object-center ">
      <img loading="lazy" :src="dialogForm.photo[1]" class=" object-cover h-12.5rem w-15.625rem object-center ">
      <img loading="lazy" :src="dialogForm.photo[2]" class=" object-cover h-12.5rem w-15.625rem object-center ">
      <img loading="lazy" :src="dialogForm.photo[3]" class=" object-cover h-12.5rem w-15.625rem object-center ">
    </div>
  </n-modal>
</template>

<style scoped></style>
