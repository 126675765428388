<script setup lang="tsx">
import type { RoleModel } from '@eggor/vue-shared'
import { AdminApi, parseMultilingual } from '@eggor/vue-shared'
import { type DataTableRowKey, NRow, NTime, type PaginationProps } from 'naive-ui'
import type { TableColumn } from 'naive-ui/es/data-table/src/interface'

definePage({
  name: 'UserAuth',
})
const route = useRoute()
const router = useRouter()
const { t } = useI18n()
const tableData = ref()
const pagination = reactive<PaginationProps>({
  page: 1,
  pageSize: 10,
  pageCount: 0,
  itemCount: 0,
  showSizePicker: true,
  pageSlot: 5,
  pageSizes: [10, 20, 30, 50],
})

const queryParams = reactive({
  nickName: '',
  userName: '',
})
const userId = computed(() => (route.params as any).userId)
const form = ref({
  userId,
  roleIds: '',
})
const checkedRowKeysRef = ref<DataTableRowKey[]>([])
function selectHandle(rowKeys: DataTableRowKey[]) {
  checkedRowKeysRef.value = rowKeys
}
const { data: updateUserAuthResult, execute: updateUserAuthModel, isFetching: updateIsFetching } = AdminApi.useUpdateModel('/system/user/authRole', {}, {}, form)
const { data, execute } = AdminApi.useUserAuthRole(userId, computed(() => ({ pageSize: pagination.pageSize, pageNum: pagination.page })), { immediate: true })
watch([data, updateUserAuthResult], () => {
  if (data.value) {
    tableData.value = data.value?.roles || []
    pagination.pageCount = Math.ceil(data.value.roles.length / (pagination.pageSize ?? 10))
    pagination.itemCount = data.value.roles.length
    queryParams.nickName = (data.value?.user.nickName) as string
    queryParams.userName = (data.value?.user.userName) as string
    data.value.roles.forEach((item) => {
      if (item.flag === true) {
        checkedRowKeysRef.value.push(item.roleId)
      }
    })
  }
  if (updateUserAuthResult.value?.code === 200) {
    handleReturn()
  }
})
watch(pagination, () => {
  execute()
})
const _columns = computed(() => i18nColumns<RoleModel>(t, 'system.userAuth', ['roleId', 'roleName', 'roleKey', 'createTime'], (key, column) => {
  column.align = 'center'
  if (key === 'roleName') {
    return {
      ...column,
      render(rowData, _rowIndex) {
        return <span>{ parseMultilingual(rowData.roleName) }</span>
      },

    }
  }
  if (key === 'createTime') {
    return {
      ...column,
      render(rowData, _rowIndex) {
        return <NTime time={new Date(rowData.createTime)}></NTime>
      },
    }
  }
  return column
}))
// console.log(_columns.value)
const columns: TableColumn[] = [{ type: 'selection' }, ...(toValue(_columns.value) as any)]
function submit() {
  form.value.roleIds = checkedRowKeysRef.value.join(',')
  updateUserAuthModel()
}
function handleReturn() {
  router.replace({ name: 'User' })
}
</script>

<template>
  <h4>
    {{ t('columns.system.userAuth.basicInformation') }}
  </h4>
  <n-divider />
  <n-form
    label-align="left" inline :model="queryParams" label-placement="left" :label-width="120"
    require-mark-placement="right-hanging" justify-around
  >
    <n-form-item :label="t('columns.system.user.nickName')" path="nickName">
      <n-input v-model:value="queryParams.nickName" disabled w-120 />
    </n-form-item>
    <n-form-item :label="t('columns.system.user.userName')" path="userName">
      <n-input v-model:value="queryParams.userName" disabled />
    </n-form-item>
  </n-form>
  <h4>
    {{ t('columns.system.userAuth.roleInformation') }}
  </h4>
  <n-divider />
  <n-data-table
    v-model:page="pagination.page" v-model:page-size="pagination.pageSize" remote :data="tableData"
    :columns="columns" :pagination="pagination" :row-key="(row:RoleModel) => (row.roleId) as number" :default-checked-row-keys="checkedRowKeysRef" @update:checked-row-keys="selectHandle"
  />
  <NRow>
    <div style="margin: 0 auto;">
      <NButton type="primary" mr-10 :disabled="updateIsFetching" @click="submit">
        {{ t('submit') }}
      </NButton>

      <NButton @click="handleReturn">
        {{ t('back') }}
      </NButton>
    </div>
  </NRow>
</template>

<style scoped>

</style>
