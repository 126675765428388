<!-- eslint-disable ts/no-use-before-define -->
<script setup lang="tsx">
import type { ReportDto } from '@eggor/vue-shared'
import { AdminApi, deltaToSummary, parseMultilingual } from '@eggor/vue-shared'
import type { FormInst, FormRules } from 'naive-ui'
import { NButton, NButtonGroup, NTag } from 'naive-ui'
import DictTagClass from '~/components/core/DictTagClass.vue'

definePage({
  name: 'reportIdentify',
})
const { t } = useI18n()
const title = ref<string>('')
const showDialog = ref<boolean>(false)
const showPreview = ref<boolean>(false)
const formRef = ref<FormInst | null>(null)
const dialogForm = ref({
  id: null,
  userId: null,
  targetUserId: null,
  type: null,
  targetId: null,
  status: null,
  eventHandle: null,
  accountHandle: null,
})
const previewRow = ref({
  createTime: '',
  updateBy: null,
  updateTime: '',
  id: '',
  userName: '',
  targetUserName: '',
  type: null,
  target: '',
  reason: '',
  eventHandle: '',
  accountHandle: '',
})
function reset() {
  dialogForm.value = {
    id: null,
    userId: null,
    targetUserId: null,
    type: null,
    targetId: null,
    status: null,
    eventHandle: null,
    accountHandle: null,
  }
  previewRow.value = {
    createTime: '',
    updateBy: null,
    updateTime: '',
    id: '',
    userName: '',
    targetUserName: '',
    type: null,
    target: '',
    reason: '',
    eventHandle: '',
    accountHandle: '',
  }
}
const rules: FormRules = {
  eventHandle: {
    required: true,
    message: t('rules.message'),
    trigger: 'blur',
  },
  accountHandle: {
    required: true,
    message: t('rules.message'),
    trigger: 'blur',
  },
}
const queryParams = reactive({ userName: '', targetUserName: '', type: '', eventHandle: '', accountHandle: '', status: '' })
const { data: checkResult, execute: checkModel, isFetching: checking } = AdminApi.useUpdateModel('eggor/report/', dialogForm)
const { data: reportStatusResult } = AdminApi.useDictChildren({ dictType: 'sys_report_status' })
const { data: reportEventResult } = AdminApi.useDictChildren({ dictType: 'sys_report_event' })
const { data: reportAccountResult } = AdminApi.useDictChildren({ dictType: 'sys_report_account' })
const { data: reportTypeResult } = AdminApi.useDictChildren({ dictType: 'sys_report_type' })
function optionGeneration(dict: any) {
  const _: any[] = []
  dict.value?.rows.forEach((item: any) => {
    const option = {
      label: parseMultilingual(item.dictLabel),
      value: item.dictValue,
    }
    _.push(option)
  },
  )
  return _
}
const reportStatusOptions = computed(() => optionGeneration(reportStatusResult))
const reportEventOptions = computed(() => optionGeneration(reportEventResult))
const reportAccountOptions = computed(() => optionGeneration(reportAccountResult))
const reportTypeOptions = computed(() => optionGeneration(reportTypeResult))

function handleCheckOver(row: any) {
  title.value = t('modify')
  reset()
  dialogForm.value = row
  showDialog.value = true
}
function handleView(row: any) {
  reset()
  title.value = t('identify')
  showPreview.value = true
  previewRow.value = row
}
function submitForm() {
  formRef.value?.validate((errors) => {
    if (errors)
      return
    if (dialogForm.value.id)
      checkModel()
  })
}
function cancel() {
  showDialog.value = false
  showPreview.value = false
}

watch([checkResult], () => {
  if (checkResult.value) {
    showDialog.value = false
    reload()
  }
})

const _columns = computed(() => i18nColumns<ReportDto>(t, 'eggor.report', ['id', 'user', 'targetUser', 'type', 'target', 'reason', 'status', 'createTime', 'actions'], (key, column) => {
  column.align = 'center'
  if (key === 'user') {
    return {
      ...column,
      render(rowData, _rowIndex) {
        return (
          <p>
            <NTag type="info">{rowData.user.nickName}</NTag>
            <br />
            { rowData.user.userName }
          </p>
        )
      },
    }
  }
  if (key === 'targetUser') {
    return {
      ...column,
      render(rowData, _rowIndex) {
        return (
          <p>
            <NTag type="info">{rowData.targetUser.nickName}</NTag>
            <br />
            { rowData.targetUser.userName }
          </p>
        )
      },
    }
  }
  if (key === 'target') {
    return {
      ...column,
      render(rowData, _rowIndex) {
        return <span class="line-clamp-4">{ deltaToSummary(rowData.target) }</span>
      },
    }
  }
  if (key === 'status') {
    return {
      ...column,
      render(rowData, _rowIndex) {
        return <DictTagClass row={reportStatusResult.value?.rows.find(item => item.dictValue === (rowData.status).toString())}></DictTagClass>
      },
    }
  }
  if (key === 'type') {
    return {
      ...column,
      render(rowData, _rowIndex) {
        return <DictTagClass row={reportTypeResult.value?.rows.find(item => item.dictValue === (rowData.type).toString())}></DictTagClass>
      },
    }
  }
  if (key === 'actions') {
    return {
      ...column,
      render(rowData, _rowIndex) {
        return (
          <NButtonGroup>
            { ['0'].includes(rowData.status.toString()) && (
              <NButton
                size="small"
                onClick={() => {
                  handleCheckOver(rowData)
                }}
                renderIcon={() => <span class="i-carbon-star-review" />}
              />
            ) }
            { ['1'].includes(rowData.status.toString()) && (
              <NButton
                size="small"
                onClick={() => {
                  handleView(rowData)
                }}
                renderIcon={() => <span class="i-carbon-view" />}
              />
            ) }
          </NButtonGroup>
        )
      },
    }
  }
  return column
}))
const { tableData, columns, pagination, selectHandle, rowKey, reload, isFetching } = useModuleDataTable<ReportDto>('eggor/report', _columns, queryParams)
function refresh() {
  reload()
}
</script>

<template>
  <n-form
    inline label-align="left"
    :model="queryParams"
    label-placement="left"
    :label-width="140"
    require-mark-placement="right-hanging"
    flex-wrap
  >
    <n-form-item :label="t('columns.eggor.report.user')" path="userName">
      <n-input v-model:value="queryParams.userName" clearable />
    </n-form-item>
    <n-form-item :label="t('columns.eggor.report.targetUser')" path="targetUserName">
      <n-input v-model:value="queryParams.targetUserName" clearable />
    </n-form-item>
    <n-form-item :label="t('columns.eggor.report.type')" path="type">
      <n-select v-model:value="queryParams.type" clearable :options="reportTypeOptions" w-40 />
    </n-form-item>
    <n-form-item :label="t('columns.eggor.report.eventHandle')" path="eventHandle">
      <n-select v-model:value="queryParams.eventHandle" clearable :options="reportEventOptions" w-40 />
    </n-form-item>
    <n-form-item :label="t('columns.eggor.report.accountHandle')" path="accountHandle">
      <n-select v-model:value="queryParams.accountHandle" clearable :options="reportAccountOptions" w-40 />
    </n-form-item>
    <n-form-item :label="t('columns.eggor.report.status')" path="status">
      <n-select
        v-model:value="queryParams.status"
        :options="reportStatusOptions" w-40
        clearable
      />
    </n-form-item>
  </n-form>
  <div mb-5>
    <NButton circle style="float: right;" @click="refresh">
      <template #icon>
        <span i-carbon-reset />
      </template>
    </NButton>
  </div>
  <n-data-table v-model:page="pagination.page" v-model:page-size="pagination.pageSize" remote :data="tableData" :columns="columns" :pagination="pagination" :row-key="rowKey" :loading="isFetching || checking" @update:checked-row-keys="selectHandle" />
  <n-modal v-model:show="showDialog" :mask-closable="false" preset="dialog" :title="title" :show-icon="false">
    <n-form
      ref="formRef"
      :model="dialogForm"
      label-placement="left"
      :label-width="110"
      label-align="left"
      :rules="rules"
    >
      <n-form-item :label="t('columns.eggor.report.id')" path="id">
        <n-input v-model:value="dialogForm.id" disabled />
      </n-form-item>
      <n-form-item :label="t('columns.eggor.report.eventHandle')" path="eventHandle">
        <n-select v-model:value="dialogForm.eventHandle" clearable :options="reportEventOptions" />
      </n-form-item>
      <n-form-item :label="t('columns.eggor.report.accountHandle')" path="accountHandle">
        <n-select v-model:value="dialogForm.accountHandle" clearable :options="reportAccountOptions" />
      </n-form-item>
    </n-form>
    <template #action>
      <NButton ghost :disabled="checking" @click="cancel">
        {{ t('cancel') }}
      </NButton>
      <NButton strong type="primary" :disabled="checking" @click="submitForm">
        {{ t('confirm') }}
      </NButton>
    </template>
  </n-modal>
  <n-modal v-model:show="showPreview" preset="dialog" :title="title" :show-icon="false" :style="{ width: '45%', maxWidth: '50rem', minWidth: '40rem' }">
    <n-form :model="previewRow" label-placement="left" :label-width="120" label-align="left">
      <n-grid :cols="24" :x-gap="24" class="h-6rem">
        <n-form-item-gi :label="t('columns.eggor.report.id')" :span="12" label-style="opacity: 70%">
          {{ previewRow.id }}
        </n-form-item-gi>
        <n-form-item-gi :label="t('columns.eggor.report.createTime')" :span="12" label-style="opacity: 70%">
          {{ previewRow.createTime }}
        </n-form-item-gi>
      </n-grid>
      <n-grid :cols="24" :x-gap="24" class="h-6rem">
        <n-form-item-gi :label="t('columns.eggor.report.updateTime')" :span="12" label-style="opacity: 70%">
          {{ previewRow.updateTime }}
        </n-form-item-gi>
        <n-form-item-gi :label="t('columns.eggor.report.updateBy')" :span="12" label-style="opacity: 70%">
          {{ previewRow.updateBy }}
        </n-form-item-gi>
      </n-grid>
      <n-grid :cols="24" :x-gap="24" class="h-6rem">
        <n-form-item-gi :label="t('columns.eggor.report.userName')" :span="12" label-style="opacity: 70%">
          {{ previewRow.userName }}
        </n-form-item-gi>
        <n-form-item-gi :label="t('columns.eggor.report.targetUserName')" :span="12" label-style="opacity: 70%">
          {{ previewRow.targetUserName }}
        </n-form-item-gi>
      </n-grid>
      <n-grid :cols="24" :x-gap="24">
        <n-form-item-gi :label="t('columns.eggor.report.type')" :span="12" label-style="opacity: 70%">
          <DictTagClass :row="reportTypeResult?.rows.find(item => item.dictValue === (previewRow.type ?? '').toString())" />
        </n-form-item-gi>
        <n-form-item-gi :label="t('columns.eggor.report.reason')" :span="12" label-style="opacity: 70%">
          {{ previewRow.reason }}
        </n-form-item-gi>
      </n-grid>
      <n-form-item :label="t('columns.eggor.report.target')" label-style="opacity: 70%">
        {{ previewRow.target }}
      </n-form-item>
      <n-grid :cols="24" :x-gap="24">
        <n-form-item-gi :label="t('columns.eggor.report.eventHandle')" :span="12" label-style="opacity: 70%">
          {{ previewRow.eventHandle }}
        </n-form-item-gi>
        <n-form-item-gi :label="t('columns.eggor.report.accountHandle')" :span="12" label-style="opacity: 70%">
          {{ previewRow.accountHandle }}
        </n-form-item-gi>
      </n-grid>
    </n-form>
    <template #action>
      <NButton ghost @click="cancel">
        {{ t('close') }}
      </NButton>
    </template>
  </n-modal>
</template>

<style scoped>

</style>
