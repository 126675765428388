<script setup lang="tsx">
import { type RouteModel, parseMultilingual } from '@eggor/vue-shared'
import type { DropdownMixedOption } from 'naive-ui/es/dropdown/src/interface'
import { RouterLink } from 'vue-router'
import SvgIconVue from './SvgIcon.vue'

const { t } = useI18n()
const route = useRoute()
const { flattenRoutes } = storeToRefs(useRoutesStore())

const matchedRoutes = computed(() => {
  const matchedRoutes = route.matched.map(match => flattenRoutes.value.find(r => r.path === match.path)).filter(_ => !!_) as RouteModel[]
  matchedRoutes.shift()
  matchedRoutes.unshift({ hidden: false, name: 'Home', path: '/', meta: { title: t('home.title'), noCache: false, icon: 'dashboard' } })
  return matchedRoutes
})

function buildDropdownOption(route: RouteModel): DropdownMixedOption {
  return {
    label: () => (
      <RouterLink to={{ path: route.path }}>
        <SvgIconVue name={route.meta.icon} style={{ marginRight: 8 }} />
        <span>{parseMultilingual(route.meta.title)}</span>
      </RouterLink>
    ),
    key: route.name,
    children: route.children?.map(buildDropdownOption),
  }
}
</script>

<template>
  <n-breadcrumb>
    <n-breadcrumb-item v-for="route in matchedRoutes" :key="route.name">
      <RouterLink :to="{ path: route.path }">
        <n-dropdown :options="route.children?.map(buildDropdownOption) ?? []">
          <div row items-center>
            <SvgIconVue :name="route.meta.icon" mr-2 />
            {{ parseMultilingual(route.meta.title) }}
          </div>
        </n-dropdown>
      </RouterLink>
    </n-breadcrumb-item>
  </n-breadcrumb>
</template>

<style scoped>

</style>
