<script setup lang="ts">
import { AdminApi } from '@eggor/vue-shared'
import type { FormInst, FormItemRule, FormRules } from 'naive-ui'

const { t } = useI18n()
const showChangePassword = defineModel<boolean>()
const form = ref({ oldPassword: '', newPassword: '', confirmPassword: '' })
const formRef = ref<FormInst | null>(null)
const rules = ref<FormRules>({
  oldPassword: {
    trigger: ['input', 'blur'],
    message: t('rules.message'),
    required: true,
  },
  newPassword: {
    trigger: ['input', 'blur'],
    validator: (rule: FormItemRule, value: string) => {
      return new Promise<void>((resolve, reject) => {
        if (!value) {
          reject(t('rules.message'))
        }
        else {
          if (value.length < 6 || value.length > 20) {
            reject(t('userNavbar.password.lengthError'))
          }
          else if (value === form.value.oldPassword) {
            reject(t('userNavbar.password.sameError'))
          }
          resolve()
        }
      })
    },
    required: true,
  },
  confirmPassword: {
    trigger: ['blur'],
    validator: (rule: FormItemRule, value: string) => {
      return new Promise<void>((resolve, reject) => {
        if (!value) {
          reject(t('rules.message'))
        }
        else {
          if (value !== form.value.newPassword) {
            reject(t('userNavbar.password.notSameError'))
          }
          resolve()
        }
      })
    },
    required: true,
  },
})
const { data: changePasswordResult, isFetching: changePasswordIsFeching, execute: changePasswordModel } = AdminApi.useUpdateModel('/system/user/profile/updatePwd', form)
function changePassword() {
  formRef.value?.validate((errors) => {
    if (errors)
      return
    changePasswordModel()
  })
}
watch(changePasswordResult, () => {
  if (changePasswordResult.value) {
    showChangePassword.value = false
  }
})
function reset() {
  form.value = {
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  }
}
function cancel() {
  showChangePassword.value = false
  reset()
}
</script>

<template>
  <n-modal v-model:show="showChangePassword" preset="dialog" :title="t('userNavbar.password.title')" :show-icon="false" :style="{ width: '25%', maxWidth: '40rem', minWidth: '20rem' }">
    <n-form ref="formRef" :rules="rules" :model="form">
      <n-form-item :label="t('userNavbar.password.oldPassword')" path="oldPassword">
        <n-input v-model:value="form.oldPassword" show-password-on="mousedown" type="password" />
      </n-form-item>
      <n-form-item :label="t('userNavbar.password.newPassword')" path="newPassword">
        <n-input v-model:value="form.newPassword" show-password-on="mousedown" type="password" />
      </n-form-item>
      <n-form-item :label="t('userNavbar.password.confirmPassword')" path="confirmPassword">
        <n-input v-model:value="form.confirmPassword" show-password-on="mousedown" type="password" />
      </n-form-item>
    </n-form>
    <template #action>
      <NButton ghost color="#7ec9bc" @click="cancel">
        {{ t('cancel') }}
      </NButton>
      <NButton strong secondary color="#409eff" :disabled="changePasswordIsFeching" @click="changePassword">
        {{ t('confirm') }}
      </NButton>
    </template>
  </n-modal>
</template>

<style scoped>

</style>
