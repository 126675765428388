import { createI18n } from 'vue-i18n'
import type { AvailableLocale } from '@eggor/vue-shared'
import type { I18nScheme, UserModule } from '~/types'

const numberFormats = {
  'en-US': {
    currency: { style: 'currency', currency: 'USD' },
    decimal: { style: 'decimal', maximumSignificantDigits: 3, minimumFractionDigits: 2 },
    percent: { style: 'percent', minimumFractionDigits: 2 },
    compact: { notation: 'compact', compactDisplay: 'short' },
  },
  'ja-JP': {
    currency: { style: 'currency', currency: 'JPY' },
    decimal: { style: 'decimal', maximumSignificantDigits: 3, minimumFractionDigits: 2 },
    percent: { style: 'percent', minimumFractionDigits: 2 },
    compact: { notation: 'compact', compactDisplay: 'short' },
  },
  'ko-KR': {
    currency: { style: 'currency', currency: 'KRW' },
    decimal: { style: 'decimal', maximumSignificantDigits: 3, minimumFractionDigits: 2 },
    percent: { style: 'percent', minimumFractionDigits: 2 },
    compact: { notation: 'compact', compactDisplay: 'short' },
  },
  'zh-CN':
    {
      currency: { style: 'currency', currency: 'CNY' },
      decimal: { style: 'decimal', maximumSignificantDigits: 3, minimumFractionDigits: 2 },
      percent: { style: 'percent', minimumFractionDigits: 2 },
      compact: { notation: 'compact', compactDisplay: 'short' },
    },
} as any

const datetimeFormats = {
  'en-US': {
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      weekday: 'short',
      hour: 'numeric',
      minute: 'numeric',
    },
  },
  'zh-CN': {
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      weekday: 'short',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    },
  },
  'ko-KR': {
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      weekday: 'short',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    },
  },
  'ja-JP': {
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      weekday: 'short',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    },
  },
} as any

const i18n = createI18n<[I18nScheme], AvailableLocale>({
  legacy: false,
  locale: '',
  fallbackLocale: 'en-US',
  messages: {} as any,
  numberFormats,
  datetimeFormats,
})

const localesMap = Object.fromEntries(
  Object.entries(import.meta.glob('../../locales/*.json'))
    .map(([path, loadLocale]) => [path.match(/([\w-]*)\.json$/)?.[1], loadLocale]),
) as Record<AvailableLocale, () => Promise<{ default: Record<string, string> }>>

export const availableLocales = Object.keys(localesMap)

const loadedLanguages: string[] = []

function setI18nLanguage(lang: AvailableLocale) {
  (i18n.global.locale as any).value = lang as any
  if (typeof document !== 'undefined')
    document.querySelector('html')?.setAttribute('lang', lang)
  return lang
}

export async function loadLanguageAsync(lang: AvailableLocale): Promise<AvailableLocale> {
  // If the same language
  if ((i18n.global.locale as any).value === lang)
    return setI18nLanguage(lang)

  // If the language was already loaded
  if (loadedLanguages.includes(lang))
    return setI18nLanguage(lang)
  // If the language hasn't been loaded yet
  const messages = await localesMap[lang]()

  i18n.global.setLocaleMessage(lang, messages.default)
  loadedLanguages.push(lang)
  return setI18nLanguage(lang)
}

export const install: UserModule = (app) => {
  app.use(i18n)
}
