import { useRouter } from 'vue-router'
import { watchPostEffect } from 'vue'
import { AdminApi, useUserStore } from '@eggor/vue-shared'

export default function (loginRoute: { name: string }, onError: (type: 'FailedToFetch' | 'Expired') => void) {
  const router = useRouter()
  const userStore = useUserStore()
  const { data: info, execute: getInfo, error } = AdminApi.useInfo({ immediate: false })

  watchPostEffect((onInvalidate) => {
    const _ = setInterval(() => {
      if (router.currentRoute.value.name === loginRoute.name)
        return
      refreshInfo().catch(() => {
        if (error.value && error.value.message === 'Failed to fetch') {
          return onError('FailedToFetch')
        }
        onError('Expired')
        userStore.logout()
        router.replace(loginRoute as any)
      })
    }, 10000)
    onInvalidate(() => clearInterval(_))
  })

  tryOnScopeDispose(router.beforeResolve((to, from, next) => {
    if (to.name === loginRoute.name)
      return next()

    if (!userStore.token) {
      userStore.logout()
      return next(loginRoute)
    }

    if (!userStore.id) {
      refreshInfo().then(() => next()).catch(() => {
        userStore.logout()
        next(loginRoute)
      })
    }
    else {
      next()
    }
  }))

  async function refreshInfo() {
    await getInfo(true)
    if (!info.value)
      throw new Error('info value is null')
    const _info = info.value!
    userStore.setInfo(_info.user, _info.permissions)
    return _info
  }
}
