import { createApp } from 'vue'
import Vue3Lottie from 'vue3-lottie'
import router from './router'
import type { UserModule } from './types'
import NavieWrapper from './components/core/NavieWrapper.vue'
import '@unocss/reset/sanitize/sanitize.css'
import '@unocss/reset/sanitize/assets.css'

import '@unocss/reset/tailwind-compat.css'
import './styles/main.css'
import 'uno.css'

const app = createApp(NavieWrapper)

app.use(Vue3Lottie, { name: 'v-lottie' })

Object.values(import.meta.glob<{ install: UserModule }>('./modules/*.ts', { eager: true }))
  .forEach(i => i.install?.(app))

app.use(router)
app.mount('#app')
