<script setup lang="ts">
import type { AvailableLocale } from '@eggor/vue-shared'
import { useAppStore } from '@eggor/vue-shared'
import { NConfigProvider, NLoadingBarProvider, NMessageProvider, darkTheme, dateEnUS, dateJaJP, dateKoKR, dateZhCN, enUS, jaJP, koKR, lightTheme, zhCN } from 'naive-ui'

import App from '~/App.vue'

const { isDark, locale } = storeToRefs(useAppStore())

const localeMap: Record<AvailableLocale, [any, any]> = {
  'zh-CN': [zhCN, dateZhCN],
  'en-US': [enUS, dateEnUS],
  'ja-JP': [jaJP, dateJaJP],
  'ko-KR': [koKR, dateKoKR],
}

const naiveLocale = computed(() => localeMap[locale.value][0])
const naiveDateLocale = computed(() => localeMap[locale.value][1])
</script>

<template>
  <NConfigProvider :theme-overrides="{}" :theme="isDark ? darkTheme : lightTheme" :locale="naiveLocale ?? enUS" :date-locale="naiveDateLocale ?? dateEnUS">
    <NMessageProvider>
      <NLoadingBarProvider>
        <n-dialog-provider>
          <App />
        </n-dialog-provider>
      </NLoadingBarProvider>
    </NMessageProvider>
  </NConfigProvider>
</template>

<style scoped>

</style>
