<script setup lang="tsx">
import { type RouteModel, parseMultilingual } from '@eggor/vue-shared'
import type { MenuInst, MenuMixedOption } from 'naive-ui/es/menu/src/interface'
import { RouterLink } from 'vue-router'
import SvgIconVue from './SvgIcon.vue'

const props = defineProps({
  collapsed: { type: Boolean }
})

const { t } = useI18n()
const menuInst = ref<MenuInst | null>(null)
const activeKey = ref('EggorCommunity')
const routesStore = storeToRefs(useRoutesStore())
const routes = computed(() => routesStore.routes.value)
const flattenRoutes = computed(() => routesStore.flattenRoutes.value)
const route = useRoute()

const menus = computed<MenuMixedOption[]>(() => {
  const _routes = routes.value.map(route => getOptionFromRoute(route))
  _routes.unshift({
    label: () => (
      <RouterLink to={{ name: 'Home' }}>
        {t('home.title')}
      </RouterLink>
    ),
    icon: () => {
      return <SvgIconVue name="monitor" size="18px" />
    },
    key: 'Home',
  })
  return _routes
})

watchPostEffect(() => {
  activeKey.value = (flattenRoutes.value.find(({ path }) => path === route.path)?.name) ?? route.name
})

onMounted(() => menuInst.value?.showOption(activeKey.value))

function getOptionFromRoute(route: RouteModel, _parent?: RouteModel): MenuMixedOption {
  const title = parseMultilingual(route.meta?.title)
  const label = ['Layout', 'ParentView'].includes(route.component ?? '')
    ? title
    : () => (
      <RouterLink to={{ path: route.path }}>
        {title}
      </RouterLink>
      )

  return ({
    label,
    key: route.name,
    icon: () => {
      return <SvgIconVue name={route.meta.icon} size="18px" />
    },
    show: !route.hidden,
    children: route.children?.map(_route => getOptionFromRoute(_route, route)),
  })
}
</script>

<template>
  <n-menu ref="menuInst" v-model:value="activeKey" select-none :options="menus" :collapsed-width="60" :collapsed-icon-size="18" :collapsed="props.collapsed" />
</template>

<style scoped>

</style>
