<script setup lang="ts">
const props = defineProps({
  name: {
    required: true,
    type: String,
  },
  color: {
    required: false,
    type: String,
  },
  size: {
    required: false,
    type: [String, Number],
  },
})

const svg = computed(() => {
  const _ = props.name
  return defineAsyncComponent(() => import(`../../assets/icons/svg/${_}.svg?component`))
})

const svgRef = ref()
const colorInDark = computed(() => props.color ?? 'white')
const colorInLight = computed(() => props.color ?? 'black')
const size = computed(() => typeof props.size === 'number' ? `${props.size}px` : props.size ?? '0.9em')

watch(svgRef, (value) => {
  if (value) {
    const el = value.$el as SVGElement
    const viewBox = el.getAttribute('viewBox')
    if (!viewBox)
      el.setAttribute('viewBox', '0 0 128 128')
  }
})
</script>

<template>
  <component :is="svg" v-if="svg" ref="svgRef" class="icon" />
</template>

<style scoped lang="scss">
.icon {
  fill: v-bind(colorInLight);
  display: inline-block;
  width: v-bind(size);
  height: v-bind(size);
  vertical-align: middle;
}
html.dark .icon {
  fill: v-bind(colorInDark);
}
</style>
