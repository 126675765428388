<script setup lang="ts">
import { AdminApi } from '@eggor/vue-shared'

const collapsed = ref(false)
const routesStore = useRoutesStore()
// const sysTablesStore = useSysTablesStore()
const fullScreenIcon = ref('fullscreen')
const { data: routeData, isFetching: isRoutesFetching, execute: getRoutes } = AdminApi.useRoutes({ immediate: false })

// const { data: tableData, isFetching: isTablesFetching, execute: getTables } = AdminApi.useToolGenList({ pageSize: 100000, pageNum: 1 })

watch([routeData], () => {
  const routes = routeData.value!.data!
  routesStore.setRoutes(routes)
})

// watch([tableData], () => {
//   const tables = tableData.value!.rows!
//   sysTablesStore.setTables(tables)
// })

onMounted(() => {
  getRoutes()
  // getTables()
})
function fullScreen() {
  const full = document.fullscreenElement
  if (!full) {
    document.documentElement.requestFullscreen()
    fullScreenIcon.value = 'exit-fullscreen'
  }
  else {
    document.exitFullscreen()
    fullScreenIcon.value = 'fullscreen'
  }
}
</script>

<template>
  <div v-if="isRoutesFetching " h="100dvh" row items-center justify-center>
    <n-spin :show="true" />
  </div>
  <n-layout v-else h="100dvh" has-sider>
    <n-layout-sider v-model:collapsed="collapsed" collapse-mode="width" :collapsed-width="60" :width="240" show-trigger="bar" bordered :native-scrollbar="false">
      <nav-menu :collapsed="collapsed" />
    </n-layout-sider>
    <n-layout>
      <n-layout-header z-100 sticky inset-x-0 top-0 bordered p-4 row items-center>
        <top-breadcrumb grow-1 />
        <locale-switcher shrink-0 />
        <svg-icon :name="fullScreenIcon" class="text-1.25rem m-x-1rem " @click="fullScreen" />
        <home-user-navbar shrink-0 />
      </n-layout-header>
      <n-layout :content-style="{ minHeight: '90dvh' }" p-4 :native-scrollbar="false">
        <RouterView />
      </n-layout>
    </n-layout>
  </n-layout>
</template>

<style lang="scss" scoped>

</style>
