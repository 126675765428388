<script setup lang="ts">
import { useUserStore } from '@eggor/vue-shared'

const { t } = useI18n()
const dialog = useDialog()
const userStore = useUserStore()
const showChangePassword = ref<boolean>(false)
const options = [{
  label: t('userNavbar.password.title'),
  key: 0,
}, {
  label: t('userNavbar.logout.title'),
  key: 1,
}]
function logout() {
  dialog.warning({
    title: t('userNavbar.logout.message'),
    positiveText: t('confirm'),
    negativeText: t('cancel'),
    onPositiveClick: () => {
      userStore.logout()
      location.href = '/index'
    },
  })
}
function handleSelect(key: number) {
  switch (key) {
    case 0:
      showChangePassword.value = true
      break
    case 1:
      logout()
      break
    default:
      break
  }
}
</script>

<template>
  <div class="avatar-container">
    <n-dropdown
      trigger="click"
      :options="options"
      :show-arrow="true"
      @select="handleSelect"
    >
      <div class="avatar-wrapper">
        <img :src="userStore.avatar" class="w-2.5rem h-2.5rem object-cover">
      </div>
    </n-dropdown>
    <home-change-password v-model="showChangePassword" />
  </div>
</template>

<style scoped>

</style>
