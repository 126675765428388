<!-- eslint-disable ts/no-use-before-define -->
<script setup lang="tsx">
import { AdminApi, parseMultilingual } from '@eggor/vue-shared'
import type { DictModel } from '@eggor/vue-shared'
import type { FormInst, FormRules } from 'naive-ui'
import { NButton, NButtonGroup, NTime } from 'naive-ui'
import router from '~/router'
import DictTagClass from '~/components/core/DictTagClass.vue'

definePage({
  name: 'Dict',
})
const { t } = useI18n()
const title = ref('')
const dialog = useDialog()
const formRef = ref<FormInst | null>(null)
const showDialog = ref<boolean>(false)
const message = useMessage()
const queryParams = reactive({
  dictType: '',
  dictName: '',
  status: '',
})
const dialogForm = ref({
  dictId: undefined,
  dictName: undefined,
  dictType: undefined,
  status: '0',
  remark: undefined,
})
const rules: FormRules = {
  dictName: {
    required: true,
    message: t('rules.message'),
    trigger: ['blur'],
  },
  dictType: {
    required: true,
    message: t('rules.message'),
    trigger: 'blur',
  },
  status: {
    required: true,
    message: t('rules.message'),
    trigger: 'blur',
  },
}
const { data: addResult, execute: addModel, isFetching: addIsFetching } = AdminApi.useAddModel('system/dict/type', dialogForm)
const { data: updateResult, execute: updateModel, isFetching: updateIsFetching } = AdminApi.useUpdateModel('system/dict/type', dialogForm)
const { data: deleteResult, execute: deleteModel, isFetching: deleteIsFetching } = AdminApi.useDeleteModel(computed(() => `system/dict/type/${dialogForm.value.dictId}`))
const { data: dictResult } = AdminApi.useDictChildren({ dictType: 'sys_normal_disable' })
const dictStatusOptions = computed(() => {
  const _: any[] = []
  dictResult.value?.rows.forEach((item) => {
    const option = {
      label: parseMultilingual(item.dictLabel),
      value: item.dictValue,
    }
    _.push(option)
  },
  )
  return _
})
watch([addResult, updateResult], () => {
  const data = addResult.value || updateResult.value
  if (data) {
    showDialog.value = false
    reload()
  }
})
watch(deleteResult, () => {
  if (deleteResult.value) {
    message.success(t('successfullyDeleted'))
    reload()
  }
})
function reset() {
  dialogForm.value = {
    dictId: undefined,
    dictName: undefined,
    dictType: undefined,
    status: '0',
    remark: undefined,
  }
}
function handleAdd() {
  reset()
  title.value = t('add')
  showDialog.value = true
}
function handleUpdate(row: any) {
  title.value = t('modify')
  reset()
  dialogForm.value = row
  showDialog.value = true
}
function submitForm() {
  formRef.value?.validate((errors) => {
    if (errors)
      return
    if (dialogForm.value.dictId)
      updateModel()
    else
      addModel()
  })
}
function cancel() {
  showDialog.value = false
}
function handleDelete(row: any) {
  dialog.warning({
    title: t('deleteHint'),
    content: t('deleteReminder'),
    positiveText: t('confirm'),
    negativeText: t('cancel'),
    onPositiveClick: () => {
      dialogForm.value = row
      deleteModel()
    },
  })
}
function handleDetail(row: DictModel) {
  const dictType = row.dictType
  router.push({ name: 'DictType', params: { dictType } })
}
const _columns = computed(() => i18nColumns<DictModel>(t, 'system.dict', ['dictId', 'dictName', 'dictType', 'status', 'remark', 'createTime', 'actions'], (key, column) => {
  column.align = 'center'
  if (key === 'dictType') {
    return {
      ...column,
      render(rowData, _rowIndex) {
        return (
          <div
            cursor-pointer
            color="#337ab7"
            onClick={() => {
              handleDetail(rowData)
            }}
          >
            {rowData.dictType}
          </div>
        )
      },
    }
  }
  if (key === 'createTime') {
    return {
      ...column,
      render(rowData, _rowIndex) {
        return <NTime time={new Date(rowData.createTime)}></NTime>
      },
    }
  }
  if (key === 'status') {
    return {
      ...column,
      render(rowData, _rowIndex) {
        return <DictTagClass row={dictResult.value?.rows.find(item => item.dictValue === rowData.status)}></DictTagClass>
      },
    }
  }
  if (key === 'actions') {
    return {
      ...column,
      render(rowData, _rowIndex) {
        return (
          <NButtonGroup size="small">
            <NButton
              secondary
              round
              type="info"
              onClick={() => {
                handleUpdate(JSON.parse(JSON.stringify(rowData)))
              }}
            >
              {t('modify')}
            </NButton>
            <NButton
              secondary
              round
              type="error"
              onClick={() => {
                handleDelete(rowData)
              }}
            >
              {t('delete')}
            </NButton>
          </NButtonGroup>
        )
      },
    }
  }
  return column
}))
const { tableData, columns, pagination, selectHandle, reload, isFetching } = useModuleDataTable<DictModel>('system/dict/type', _columns, queryParams)
function refresh() {
  reload()
}
</script>

<template>
  <n-form
    inline :model="queryParams" label-align="left" label-placement="left" :label-width="140"
    require-mark-placement="right-hanging" flex-wrap
  >
    <n-form-item :label="t('columns.system.dict.dictName')" path="dictName">
      <n-input v-model:value="queryParams.dictName" clearable />
    </n-form-item>
    <n-form-item :label="t('columns.system.dict.dictType')" path="dictType">
      <n-input v-model:value="queryParams.dictType" clearable />
    </n-form-item>
    <n-form-item :label="t('columns.system.dict.status')" path="status">
      <n-select v-model:value="queryParams.status" w-45 :options="dictStatusOptions" clearable />
    </n-form-item>
  </n-form>
  <div mb-5>
    <NButton mr-5 type="primary" :disabled="addIsFetching || updateIsFetching" @click="handleAdd">
      <template #icon>
        <span i-carbon-add />
      </template>{{ t('add') }}
    </NButton>
    <NButton circle style="float: right;" @click="refresh">
      <template #icon>
        <span i-carbon-reset />
      </template>
    </NButton>
  </div>
  <n-data-table
    v-model:page="pagination.page" v-model:page-size="pagination.pageSize" remote :data="tableData"
    :columns="columns" :pagination="pagination" :row-key="(row:DictModel) => row.dictId"
    :loading="isFetching || addIsFetching || updateIsFetching || deleteIsFetching" @update:checked-row-keys="selectHandle"
  />
  <n-modal v-model:show="showDialog" :mask-closable="false" preset="dialog" :title="title" :show-icon="false">
    <n-form ref="formRef" :rules="rules" :model="dialogForm" label-placement="left" :label-width="110" label-align="left">
      <n-form-item :label="t('columns.system.dict.dictName')" path="dictName">
        <n-input v-model:value="dialogForm.dictName" clearable />
      </n-form-item>
      <n-form-item :label="t('columns.system.dict.dictType')" path="dictType">
        <n-input v-model:value="dialogForm.dictType" clearable />
      </n-form-item>
      <n-form-item :label="t('columns.system.dict.status')" path="status">
        <n-radio-group v-model:value="dialogForm.status" name="radiogroup">
          <n-radio v-for="option in dictStatusOptions" :key="option.label" :value="option.value">
            {{ option.label }}
          </n-radio>
        </n-radio-group>
      </n-form-item>
      <n-form-item :label="t('remark')" path="remark" :span="12">
        <n-input v-model:value="dialogForm.remark" type="textarea" clearable />
      </n-form-item>
    </n-form>
    <template #action>
      <NButton ghost @click="cancel">
        {{ t('cancel') }}
      </NButton>
      <NButton strong type="primary" :disabled="addIsFetching || updateIsFetching" @click="submitForm">
        {{ t('confirm') }}
      </NButton>
    </template>
  </n-modal>
</template>

<style scoped></style>
