<script setup lang="ts">
import { useAppStore } from '@eggor/vue-shared'
import type { SelectOption } from 'naive-ui'
import { availableLocales } from '~/modules/i18n'

const { locale, t } = useI18n()
const appStore = useAppStore()
const i18nOptions = computed(() => availableLocales.map<SelectOption>(locale => ({
  label: t(`localeLanguage.${locale}`),
  value: locale,
})))
</script>

<template>
  <n-popselect :value="locale" :options="i18nOptions" trigger="click" @update-value="(value) => appStore.locale = value">
    <n-button size="small" secondary>
      <template #icon>
        <span i-carbon-ibm-watson-language-translator />
      </template>
      <span>{{ t(`localeLanguage.${locale}`) }}</span>
    </n-button>
  </n-popselect>
</template>

<style scoped>

</style>
