<script setup lang="tsx">
import type { BaseModel } from '@eggor/vue-shared'
import { NAvatar, NButton, NTime } from 'naive-ui'

definePage({ name: 'LikeUser' })
const { t } = useI18n()
const route = useRoute()
const router = useRouter()
const id = computed(() => (route.params as any).id)
const type = computed(() => (route.query as any).type)
interface LikeUser extends BaseModel {
  userId: number
  nickName: string
  avatar: string
  bio: string

}

const _columns = computed(() => i18nColumns<LikeUser>(t, 'eggor.likeUser', ['id', 'userId', 'avatar', 'nickName', 'bio', 'createTime'], (key, column) => {
  column.align = 'center'
  if (key === 'avatar') {
    return {
      ...column,
      render(rowData, _rowIndex) {
        return <NAvatar size="large" src={rowData.avatar} />
      },
    }
  }
  if (key === 'createTime') {
    return {
      ...column,
      render(rowData, _rowIndex) {
        return <NTime time={new Date(rowData.createTime)}></NTime>
      },
    }
  }
  return column
}))

const { tableData, columns, pagination, selectHandle, rowKey, reload, isFetching } = useModuleDataTable<LikeUser>(computed(() => type.value === 'post' ? `eggor/postLikes/user/${id.value}` : `/eggor/commentLikes/user/${id.value}`), _columns, {})
function refresh() {
  reload()
}
function handleClose() {
  router.back()
}
</script>

<template>
  <div mb-5>
    <NButton type="default" @click="handleClose">
      <template #icon>
        <span i-carbon-close />
      </template>{{ t('close') }}
    </NButton>
    <NButton circle style="float: right;" @click="refresh">
      <template #icon>
        <span i-carbon-reset />
      </template>
    </NButton>
  </div>
  <n-data-table
    v-model:page="pagination.page" v-model:page-size="pagination.pageSize" remote :data="tableData"
    :columns="columns" :pagination="pagination" :row-key="rowKey" :loading="isFetching" @update:checked-row-keys="selectHandle"
  />
</template>

<style scoped>

</style>
