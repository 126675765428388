<script setup lang="ts">
definePage({
  redirect: { name: 'Home' },
})
</script>

<template>
  <div />
</template>

<style scoped>

</style>
