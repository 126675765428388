<script setup lang="tsx">
import { AdminApi, type CommentModel, WebsiteApi, deltaToSummary } from '@eggor/vue-shared'
import type { FormInst, PaginationProps } from 'naive-ui'
import { NButton, NButtonGroup, NTag, NTime } from 'naive-ui'
import { parseMultilingual } from '@eggor/vue-shared'
import DictTagClass from '~/components/core/DictTagClass.vue'

definePage({
  name: 'Comment',
})
const { t } = useI18n()
const message = useMessage()
const dialog = useDialog()
const router = useRouter()
const route = useRoute()
const postId = route.query.postId
const formRef = ref<FormInst | null>(null)
const showDetail = ref<boolean>(false)
const dialogForm = ref({
  id: null,
  userName: '',
  content: '',
  type: null,
  targetId: null,
  replyNum: null,
  likedNum: null,
  createTime: null,
  updateTime: null,
  photo: [],
  video: [],
  audio: [],
})
function reset() {
  dialogForm.value = {
    id: null,
    userName: '',
    content: '',
    type: null,
    targetId: null,
    replyNum: null,
    likedNum: null,
    createTime: null,
    updateTime: null,
    photo: [],
    video: [],
    audio: [],
  }
}
function optionsGenerator(dict: any) {
  const _: any[] = []
  dict.value?.rows.forEach((item: any) => {
    const option = {
      label: parseMultilingual(item.dictLabel),
      value: item.dictValue,
    }
    _.push(option)
  },
  )
  return _
}
function showChildrenComment(rowData: CommentModel) {
  if (rowData.type === 1 && rowData.replyNum !== 0) {
    router.push({ name: 'commentChildren', params: { targetId: rowData.id } })
  }
  else if (rowData.type === 1 && rowData.replyNum === 0) {
    message.info(t('columns.eggor.comment.isHaveChildren'))
  }
}
function showLike(rowData: CommentModel) {
  if (rowData.likedNum !== 0) {
    router.push({ name: 'LikeUser', params: { id: rowData.id }, query: { type: 'comment' } })
  }
  else {
    message.info(t('columns.eggor.comment.isHaveliked'))
  }
}
function changeShowContent(data: any) {
  reset()
  dialogForm.value.content = deltaToSummary(data.content)
  data.content.ops.forEach((item: any) => {
    if (item.insert) {
      if (item.insert.image) {
        dialogForm.value.photo.push(item.insert.image as never)
      }
      if (item.insert.video) {
        dialogForm.value.video.push(item.insert.video as never)
      }
      if (item.insert.audio) {
        dialogForm.value.audio.push(item.insert.audio as never)
      }
    }
  })
  showDetail.value = true
}
const queryParams = reactive({ userName: '', type: '', content: '', id: '', communityName: '', postId })
const { data: commentTypeResult } = AdminApi.useDictChildren({ dictType: 'sys_comment_type' })
const commentTypeOptions = computed(() => {
  const _ = optionsGenerator(commentTypeResult)
  _.pop()
  return _
})
const { data: deleteResult, execute: deleteModel, isFetching: deleteIsFetching } = AdminApi.useDeleteModel(computed(() => `eggor/comment/${dialogForm.value.id}`))
const _columns = computed(() => i18nColumns<CommentModel>(t, 'eggor.comment', ['id', 'user', 'content', 'type', 'replyNum', 'likedNum', 'createTime', 'actions'], (key, column) => {
  column.align = 'center'
  if (key === 'user') {
    return {
      ...column,
      render(rowData, _rowIndex) {
        return (
          <p>
            <NTag type="info">{rowData.user.nickName}</NTag>
            <br />
            { rowData.user.userName }
          </p>
        )
      },
    }
  }
  if (key === 'content') {
    return {
      ...column,
      width: '15rem',
      maxWidth: '35%',
      render(rowData, _rowIndex) {
        return (
          <div
            class="h-4.1875rem flex justify-center items-center"
            onClick={() => { changeShowContent(rowData) }}

          >
            <p
              class="line-clamp-3"
            >
              {deltaToSummary(rowData.content)}
            </p>
          </div>
        )
      },
    }
  }
  if (key === 'type') {
    return {
      ...column,
      render(rowData, _rowIndex) {
        return <DictTagClass row={commentTypeResult.value?.rows.find(item => item.dictValue === (rowData.type).toString())}></DictTagClass>
      },
    }
  }
  if (key === 'replyNum') {
    return {
      ...column,
      render(rowData, _rowIndex) {
        return (
          <div
            onClick={() => {
              showChildrenComment(JSON.parse(JSON.stringify(rowData)))
            }}
          >
            {rowData.replyNum}
          </div>
        )
      },
    }
  }
  if (key === 'likedNum') {
    return {
      ...column,
      render(rowData, _rowIndex) {
        return (
          <div
            onClick={() => {
              showLike(JSON.parse(JSON.stringify(rowData)))
            }}
          >
            {rowData.likedNum}
          </div>
        )
      },
    }
  }
  if (key === 'createTime') {
    return {
      ...column,
      render(rowData, _rowIndex) {
        return <NTime time={new Date(rowData.createTime)}></NTime>
      },
    }
  }
  if (key === 'actions') {
    return {
      ...column,
      render(rowData, _rowIndex) {
        return (
          <NButtonGroup size="small">
            {/* <NButton secondary round type='info' onClick={() => {
            handleUpdate(JSON.parse(JSON.stringify(rowData)))
          }}>
            {t('modify')}
          </NButton> */}
            <NButton
              secondary
              round
              type="error"
              onClick={() => {
                handleDelete(rowData)
              }}
            >
              {t('delete')}
            </NButton>
          </NButtonGroup>
        )
      },
    }
  }
  return column
}))

function handleDelete(row: any) {
  dialog.warning({
    title: t('deleteHint'),
    content: t('deleteReminder'),
    positiveText: t('confirm'),
    negativeText: t('cancel'),
    onPositiveClick: () => {
      dialogForm.value = row
      deleteModel()
    },
  })
}
watch([deleteResult], () => {
  if (deleteResult.value?.code === 200) {
    message.success(t('successfullyDeleted'))
    // eslint-disable-next-line ts/no-use-before-define
    reload()
  }
})

const { tableData, columns, pagination, selectHandle, rowKey, reload, isFetching } = useModuleDataTable<CommentModel>('eggor/comment', _columns, queryParams)
function refresh() {
  reload()
}
</script>

<template>
  <n-form
    ref="formRef" inline :model="queryParams" label-align="left" label-placement="left" :label-width="100"
    require-mark-placement="right-hanging" flex-wrap
  >
    <n-form-item :label="t('id')" path="id">
      <n-input v-model:value="queryParams.id" clearable />
    </n-form-item>
    <n-form-item :label="t('columns.eggor.comment.user')" path="userName">
      <n-input v-model:value="queryParams.userName" clearable />
    </n-form-item>
    <n-form-item :label="t('columns.eggor.comment.type')" path="type">
      <n-select v-model:value="queryParams.type" :options="commentTypeOptions" w-45 clearable />
    </n-form-item>
    <n-form-item :label="t('columns.eggor.comment.content')" path="type">
      <n-input v-model:value="queryParams.content" clearable />
    </n-form-item>
    <n-form-item :label="t('columns.eggor.comment.communityName')" path="communityName">
      <n-input v-model:value="queryParams.communityName" clearable />
    </n-form-item>
    <n-form-item :label="t('columns.eggor.comment.post')" path="post">
      <n-input v-model:value="queryParams.postId" clearable />
    </n-form-item>
  </n-form>
  <div mb-5>
    <NButton circle style="float: right;" @click="refresh">
      <template #icon>
        <span i-carbon-reset />
      </template>
    </NButton>
  </div>
  <n-data-table
    v-model:page="pagination.page" v-model:page-size="pagination.pageSize" remote :data="tableData"
    :columns="columns" :pagination="pagination" :row-key="rowKey" :loading="isFetching || deleteIsFetching" @update:checked-row-keys="selectHandle"
  />
  <n-modal v-model:show="showDetail" :mask-closable="false" preset="card" :show-icon="false" style="width:auto; min-width: 30%; max-width: 70%;">
    <div v-if="dialogForm.content">
      {{ dialogForm.content }}
    </div>
    <div v-if="dialogForm.video.length > 0" class="w-auto">
      <video v-for="(i, v) in dialogForm.video" :key="v" :src="i" controls class="h-25rem w-auto" />
    </div>
    <div v-if="dialogForm.audio.length > 0">
      <audio v-for="(i, v) in dialogForm.audio" :key="v" :src="i" controls />
    </div>
    <div v-if="dialogForm.photo.length === 1" class="row gap-4 mt-1.25rem max-h-24.375rem flex-wrap w-fit max-w-500px">
      <img loading="lazy" :src="dialogForm.photo[0]" class="rounded object-contain max-h-24.375rem  max-w-full">
    </div>
    <div v-else-if="dialogForm.photo.length === 2" class="row gap-1 mt-1.25rem rounded overflow-hidden w-fit">
      <img v-for="photo in dialogForm.photo" :key="photo" loading="lazy" :src="photo" class=" object-cover h-24.375rem grow object-center">
    </div>
    <div v-else-if="dialogForm.photo.length === 3" class="row gap-1 mt-1.25rem h-24.375rem  rounded overflow-hidden w-fit">
      <img loading="lazy" :src="dialogForm.photo[0]" class=" object-cover w-15.625rem max-w-50% object-center  h-full">
      <div class="col gap-1 max-w-50%">
        <img loading="lazy" :src="dialogForm.photo[1]" class=" object-cover h-12.5rem w-15.625rem max-w-full object-center ">
        <img loading="lazy" :src="dialogForm.photo[2]" class=" object-cover h-12.5rem w-15.625rem max-w-full object-center ">
      </div>
    </div>
    <div v-else-if="dialogForm.photo.length >= 4" class="grid  grid-cols-2 gap-1 mt-1.25rem rounded overflow-hidden w-fit">
      <img loading="lazy" :src="dialogForm.photo[0]" class=" object-cover h-12.5rem w-15.625rem object-center ">
      <img loading="lazy" :src="dialogForm.photo[1]" class=" object-cover h-12.5rem w-15.625rem object-center ">
      <img loading="lazy" :src="dialogForm.photo[2]" class=" object-cover h-12.5rem w-15.625rem object-center ">
      <img loading="lazy" :src="dialogForm.photo[3]" class=" object-cover h-12.5rem w-15.625rem object-center ">
    </div>
  </n-modal>
</template>

<style scoped></style>
