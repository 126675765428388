<!-- eslint-disable ts/no-use-before-define -->
<script setup lang="tsx">
import { AdminApi, parseMultilingual } from '@eggor/vue-shared'
import type { DictDetailModel } from '@eggor/vue-shared'
import type { FormInst, FormRules } from 'naive-ui'
import { NButton, NButtonGroup, NTime } from 'naive-ui'
import DictTagClass from '~/components/core/DictTagClass.vue'

definePage({
  name: 'DictType',
})
const { t } = useI18n()
const title = ref('')
const router = useRouter()
const dialog = useDialog()
const route = useRoute()
const formRef = ref<FormInst | null>(null)
const showDialog = ref<boolean>(false)
const message = useMessage()
const typeOptions = ref()
const dictTypeDefault = computed(() => (route.params as any).dictType)
const queryParams = reactive({
  dictType: '',
  dictName: '',
  status: '',
  dictLabel: '',
})
queryParams.dictType = dictTypeDefault.value
const dialogForm = ref({
  dictType: '',
  dictCode: '',
  dictLabel: '',
  dictValue: '',
  cssClass: '',
  listClass: 'default',
  dictSort: 0,
  status: '0',
  remark: '',
})
const { data: addResult, execute: addModel, isFetching: addIsFetching } = AdminApi.useAddModel('system/dict/data', dialogForm)
const { data: updateResult, execute: updateModel, isFetching: updateIsFetching } = AdminApi.useUpdateModel('system/dict/data', dialogForm)
const { data: deleteResult, execute: deleteModel, isFetching: deleteIsFetching } = AdminApi.useDeleteModel(computed(() => `system/dict/data/${dialogForm.value.dictCode}`))
const { data: dictTypeResult } = AdminApi.useDict('optionselect')
const { data: dictResult } = AdminApi.useDictChildren({ dictType: 'sys_normal_disable' })
const { data: dictListClassResult } = AdminApi.useDictChildren({ dictType: 'sys_list_class' })
function optionGeneration(dict: any) {
  const _: any[] = []
  dict.value?.rows.forEach((item: any) => {
    const option = {
      label: parseMultilingual(item.dictLabel),
      value: item.dictValue,
    }
    _.push(option)
  },
  )
  return _
}
const dictStatusOptions = computed(() => optionGeneration(dictResult))
const dictListClassOptions = computed(() => optionGeneration(dictListClassResult))
watch([addResult, updateResult, dictTypeResult], () => {
  const data = addResult.value || updateResult.value
  if (data) {
    showDialog.value = false
    reload()
  }
  if (dictTypeResult.value) {
    typeOptions.value = computed(() => {
      const _: any[] = []
      dictTypeResult.value?.data.forEach((item: any) => {
        const option = {
          label: item.dictName,
          value: item.dictType,
        }
        _.push(option)
      },
      )
      return _
    }).value
  }
  if (deleteResult.value?.code === 200)
    message.success(t('successfullyDeleted'))
})
function reset() {
  dialogForm.value = {
    dictType: '',
    dictCode: '',
    dictLabel: '',
    dictValue: '',
    cssClass: '',
    listClass: 'default',
    dictSort: 0,
    status: '0',
    remark: '',
  }
}
const rules: FormRules = {

  dictLabel: {
    required: true,
    message: t('rules.message'),
    trigger: ['blur'],
  },
  dictValue: {
    required: true,
    message: t('rules.message'),
    trigger: 'blur',
  },
}
function handleAdd() {
  reset()
  title.value = t('add')
  dialogForm.value.dictType = queryParams.dictType
  showDialog.value = true
}
function handleUpdate(row: any) {
  title.value = t('modify')
  reset()
  dialogForm.value = row
  showDialog.value = true
}
function handleClose() {
  router.replace({ name: 'Dict' })
}
function submitForm() {
  formRef.value?.validate((errors) => {
    if (errors)
      return
    if (dialogForm.value.dictCode)
      updateModel()
    else
      addModel()
  })
}
function cancel() {
  showDialog.value = false
}
function handleDelete(row: any) {
  dialog.warning({
    title: t('deleteHint'),
    content: t('deleteReminder'),
    positiveText: t('confirm'),
    negativeText: t('cancel'),
    onPositiveClick: () => {
      dialogForm.value = row
      deleteModel()
    },
  })
}

const _columns = computed(() => i18nColumns<DictDetailModel>(t, 'system.dict', ['dictCode', 'dictLabel', 'dictValue', 'dictSort', 'status', 'remark', 'createTime', 'actions'], (key, column) => {
  column.align = 'center'

  if (key === 'dictLabel') {
    return {
      ...column,
      render(rowData, _rowIndex) {
        return <DictTagClass row={rowData}></DictTagClass>
      },
    }
  }
  if (key === 'status') {
    return {
      ...column,
      render(rowData, _rowIndex) {
        return <span>{rowData.status}</span>
      },
    }
  }
  if (key === 'createTime') {
    return {
      ...column,
      render(rowData, _rowIndex) {
        return <NTime time={new Date(rowData.createTime)}></NTime>
      },
    }
  }
  if (key === 'actions') {
    return {
      ...column,
      render(rowData, _rowIndex) {
        return (
          <NButtonGroup size="small">
            <NButton
              secondary
              round
              type="info"
              onClick={() => {
                handleUpdate(JSON.parse(JSON.stringify(rowData)))
              }}
            >
              {t('modify')}
            </NButton>
            <NButton
              secondary
              round
              type="error"
              onClick={() => {
                handleDelete(rowData)
              }}
            >
              {t('delete')}
            </NButton>
          </NButtonGroup>
        )
      },
    }
  }
  return column
}))
const { tableData, columns, pagination, selectHandle, reload, isFetching } = useModuleDataTable<DictDetailModel>('system/dict/data', _columns, queryParams)
function refresh() {
  reload()
}
</script>

<template>
  <n-form
    inline :model="queryParams" label-align="left" label-placement="left" :label-width="140"
    require-mark-placement="right-hanging" flex-wrap
  >
    <n-form-item :label="t('columns.system.dict.dictType')" path="dictType">
      <n-select v-model:value="queryParams.dictType" w-45 :options="typeOptions" clearable />
    </n-form-item>
    <n-form-item :label="t('columns.system.dict.dictLabel')" path="dictLabel">
      <n-input v-model:value="queryParams.dictLabel" clearable />
    </n-form-item>
    <n-form-item :label="t('columns.system.dict.status')" path="status">
      <n-select v-model:value="queryParams.status" w-45 :options="dictStatusOptions" clearable />
    </n-form-item>
  </n-form>
  <div mb-5>
    <NButton mr-5 type="primary" :disabled="addIsFetching || updateIsFetching" @click="handleAdd">
      <template #icon>
        <span i-carbon-add />
      </template>{{ t('add') }}
    </NButton>
    <NButton type="default" @click="handleClose">
      <template #icon>
        <span i-carbon-close />
      </template>{{ t('close') }}
    </NButton>
    <NButton circle style="float: right;" @click="refresh">
      <template #icon>
        <span i-carbon-reset />
      </template>
    </NButton>
  </div>
  <n-data-table
    v-model:page="pagination.page" v-model:page-size="pagination.pageSize" remote :data="tableData"
    :columns="columns" :pagination="pagination" :row-key="(row:DictDetailModel) => row.dictCode"
    :loading="addIsFetching || updateIsFetching || deleteIsFetching || isFetching" @update:checked-row-keys="selectHandle"
  />
  <n-modal v-model:show="showDialog" :mask-closable="false" preset="dialog" :title="title" :show-icon="false" style="width: 30%; min-width: 15rem;">
    <n-form ref="formRef" :rules="rules" :model="dialogForm" label-placement="left" :label-width="110" label-align="left">
      <n-form-item :label="t('columns.system.dict.dictType')" path="dictType">
        <n-input v-model:value="dialogForm.dictType" clearable disabled />
      </n-form-item>
      <n-form-item :label="t('columns.system.dict.dictLabel')" path="dictLabel">
        <n-popover trigger="click" placement="bottom">
          <template #trigger>
            <n-input :value="parseMultilingual(dialogForm.dictLabel)" readonly />
          </template>
          <template #default>
            <locale-input v-model="dialogForm.dictLabel" style="width: 100%;min-width: 25rem;max-width: 40rem;" />
          </template>
        </n-popover>
      </n-form-item>
      <n-form-item :label="t('columns.system.dict.dictValue')" path="dictValue">
        <n-input v-model:value="dialogForm.dictValue" clearable />
      </n-form-item>
      <n-form-item :label="t('columns.system.dict.cssClass')" path="cssClass">
        <n-input v-model:value="dialogForm.cssClass" clearable />
      </n-form-item>
      <n-form-item :label="t('columns.system.dict.dictSort')" path="dictSort">
        <n-input-number v-model:value="dialogForm.dictSort" clearable />
      </n-form-item>
      <n-form-item :label="t('columns.system.dict.listClass')" path="listClass">
        <n-select v-model:value="dialogForm.listClass" :options="dictListClassOptions" clearable />
      </n-form-item>
      <n-form-item :label="t('columns.system.dict.status')" path="status">
        <n-radio-group v-model:value="dialogForm.status" name="radiogroup">
          <n-radio v-for="option in dictStatusOptions" :key="option.label" :value="option.value">
            {{ option.label }}
          </n-radio>
        </n-radio-group>
      </n-form-item>
      <n-form-item :label="t('remark')" path="remark" :span="12">
        <n-input v-model:value="dialogForm.remark" type="textarea" clearable />
      </n-form-item>
    </n-form>
    <template #action>
      <NButton ghost @click="cancel">
        {{ t('cancel') }}
      </NButton>
      <NButton strong type="primary" :disabled="addIsFetching || updateIsFetching" @click="submitForm">
        {{ t('confirm') }}
      </NButton>
    </template>
  </n-modal>
</template>

<style scoped></style>
