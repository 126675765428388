import type { RouteModel } from '@eggor/vue-shared'

function flattenRoutes(routes: RouteModel[]): RouteModel[] {
  const _routes = JSON.parse(JSON.stringify(routes)) as RouteModel[]
  function map(route: RouteModel, parent?: RouteModel): RouteModel[] {
    route.path = (`${`${parent?.path ? `${parent?.path}/` : ''}`}${route.path}`)
    return [route, ...route.children?.flatMap(_ => map(_, route)) ?? []]
  }
  return _routes.flatMap(_ => map(_))
}

function formatRoutePath(routes: RouteModel[]): RouteModel[] {
  const _routes = JSON.parse(JSON.stringify(routes)) as RouteModel[]
  function format(route: RouteModel, parent?: RouteModel): RouteModel {
    route.path = (`${`${parent?.path ? `${parent?.path}/` : ''}`}${route.path}`)
    if (route.children) {
      route.children.map(_ => format(_, route))
    }
    return route
  }
  return _routes.map(_ => format(_))
}

const useRoutesStore = defineStore('routes', {
  state: () => ({
    routes: useLocalStorage<RouteModel[]>('routes', []),
    flattenRoutes: useLocalStorage<RouteModel[]>('flatten_routes', []),
  }),
  actions: {
    setRoutes(routes: RouteModel[]) {
      this.routes = formatRoutePath(routes)
      this.flattenRoutes = flattenRoutes(routes)
    },
  },
})

export default useRoutesStore
