<script setup lang="tsx">
import type { CommentChild, CommentModel } from '@eggor/vue-shared'
import { AdminApi, deltaToSummary } from '@eggor/vue-shared'
import { NButton, NButtonGroup, NTag, NTime } from 'naive-ui'

definePage({ name: 'commentChildren' })
const { t } = useI18n()
const route = useRoute()
const router = useRouter()
const dialog = useDialog()
const message = useMessage()
const targetId = computed(() => (route.params as any).targetId)
const queryParams = reactive({ fromUser: '', toUser: '', type: '4', content: '', id: '', targetId })
const dialogForm = ref({
  id: null,
  toUser: '',
  formUser: '',
  content: '',
  type: null,
  targetId: null,
  createTime: null,
})
const { data: deleteResult, execute: deleteModel, isFetching: deleteIsFetching } = AdminApi.useDeleteModel(computed(() => `eggor/comment/children/${dialogForm.value.id}`))
function handleDelete(row: any) {
  dialog.warning({
    title: t('deleteHint'),
    content: t('deleteReminder'),
    positiveText: t('confirm'),
    negativeText: t('cancel'),
    onPositiveClick: () => {
      dialogForm.value = row
      deleteModel()
    },
  })
}
watch([deleteResult], () => {
  if (deleteResult.value?.code === 200) {
    message.success(t('successfullyDeleted'))
    // eslint-disable-next-line ts/no-use-before-define
    reload()
  }
})

const _columns = computed(() => i18nColumns<CommentChild>(t, 'eggor.commentChildren', ['id', 'fromUser', 'toUser', 'content', 'createTime', 'actions'], (key, column) => {
  column.align = 'center'
  if (key === 'fromUser') {
    return {
      ...column,
      render(rowData, _rowIndex) {
        return (
          <p>
            <NTag type="info">{rowData.fromUser.nickName}</NTag>
            <br />
            { rowData.fromUser.userName }
          </p>
        )
      },
    }
  }
  if (key === 'toUser') {
    return {
      ...column,
      render(rowData, _rowIndex) {
        return (
          <p v-if="rowData.toUser">
            <NTag type="info">{rowData.toUser?.nickName}</NTag>
            <br />
            { rowData.toUser?.userName }
          </p>
        )
      },
    }
  }
  if (key === 'content') {
    return {
      ...column,
      width: '50%',
      render(rowData, _rowIndex) {
        return <span class="line-clamp-4">{ deltaToSummary(rowData.content) }</span>
      },
    }
  }
  if (key === 'createTime') {
    return {
      ...column,
      render(rowData, _rowIndex) {
        return <NTime time={new Date(rowData.createTime)}></NTime>
      },
    }
  }

  if (key === 'actions') {
    return {
      ...column,
      render(rowData, _rowIndex) {
        return (
          <NButtonGroup size="small">
            <NButton
              secondary
              round
              type="error"
              onClick={() => {
                handleDelete(rowData)
              }}
            >
              {t('delete')}
            </NButton>
          </NButtonGroup>
        )
      },
    }
  }
  return column
}))
const { tableData, columns, pagination, selectHandle, rowKey, reload, isFetching } = useModuleDataTable<CommentModel>('eggor/comment/children', _columns, queryParams)
function refresh() {
  reload()
}
function handleClose() {
  router.back()
}
</script>

<template>
  <n-form
    inline :model="queryParams" label-align="left" label-placement="left" :label-width="100"
    require-mark-placement="right-hanging" flex-wrap
  >
    <n-form-item :label="t('id')" path="id">
      <n-input v-model:value="queryParams.id" clearable />
    </n-form-item>
    <n-form-item :label="t('columns.eggor.commentChildren.fromUser')" path="fromUser">
      <n-input v-model:value="queryParams.fromUser" clearable />
    </n-form-item>
    <n-form-item :label="t('columns.eggor.commentChildren.toUser')" path="toUser">
      <n-input v-model:value="queryParams.toUser" clearable />
    </n-form-item>
    <n-form-item :label="t('columns.eggor.commentChildren.content')" path="type">
      <n-input v-model:value="queryParams.content" clearable />
    </n-form-item>
  </n-form>
  <div mb-5>
    <NButton type="default" @click="handleClose">
      <template #icon>
        <span i-carbon-close />
      </template>{{ t('close') }}
    </NButton>
    <NButton circle style="float: right;" @click="refresh">
      <template #icon>
        <span i-carbon-reset />
      </template>
    </NButton>
  </div>
  <n-data-table
    v-model:page="pagination.page" v-model:page-size="pagination.pageSize" remote :data="tableData"
    :columns="columns" :pagination="pagination" :row-key="rowKey" :loading="isFetching || deleteIsFetching" @update:checked-row-keys="selectHandle"
  />
</template>

<style scoped>

</style>
