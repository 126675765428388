<script setup lang="tsx">
import { AdminApi, parseMultilingual } from '@eggor/vue-shared'
import type { Result, RoleModel } from '@eggor/vue-shared'
import type { FormInst, FormRules } from 'naive-ui'
import { NButton, NSwitch, NTime } from 'naive-ui'
import { h } from 'vue'

definePage({
  name: 'Role',
})
const { t } = useI18n()
const title = ref('')
const dialog = useDialog()
const menuTreeData = ref()
const dataTreeDate = ref()
const router = useRouter()
const checkStrictly = ref<boolean>(false)
const menuChecked = ref<number[]>([])
const deptChecked = ref<number[]>([])
const openDataScope = ref<boolean>(false)
const formRef = ref<FormInst | null>(null)
const showDialog = ref<boolean>(false)
const message = useMessage()
const queryParams = reactive({
  roleName: '',
  roleKey: '',
  status: '',
})
const dialogForm = ref({
  roleId: null,
  roleName: '',
  roleKey: '',
  roleSort: 0,
  status: '0',
  menuIds: [],
  deptIds: [],
  menuCheckStrictly: true,
  deptCheckStrictly: true,
  remark: '',
  dataScope: '',
  dataPermissions: '',
  menuPermissions: '',
})
const rules: FormRules = {
  roleName: {
    required: true,
    message: t('rules.message'),
    trigger: ['blur'],
  },
  roleKey: {
    required: true,
    message: t('rules.message'),
    trigger: 'blur',
  },
}
const changeRoleStatusForm = ref({
  roleId: 100,
  status: '0',
})
const { data: menuCheckResult, execute: menuCheckModel } = AdminApi.useModuleApi(computed(() => `/system/menu/roleMenuTreeselect/${dialogForm.value.roleId ?? ''}`), {}, { immediate: false }).get().json()
const { data: menuResult, execute: menuModel } = AdminApi.useModuleApi('/system/menu/treeselect', {}, { immediate: false }).get().json()
const { data: addResult, execute: addModel, isFetching: addIsFetching } = AdminApi.useAddModel('system/role', dialogForm)
const { data: updateResult, execute: updateModel, isFetching: updateIsFetching } = AdminApi.useUpdateModel('system/role', dialogForm)
const { data: updateDaraScopeResult, execute: updateDataScopeModel } = AdminApi.useUpdateModel('system/role/dataScope', dialogForm)
const { data: deleteResult, execute: deleteModel, isFetching: deleteIsFetching } = AdminApi.useDeleteModel(computed(() => `system/role/${dialogForm.value.roleId}`))
const { data: changeRoleStatusResult, isFetching: roleStatusChanging, error: changeRoleStatusError, execute: changeRoleStatus } = AdminApi.useModuleApi('system/role/changeStatus', {}, { immediate: false }).put(computed(() => (changeRoleStatusForm.value))).json<Result>()
const { data: dictResult } = AdminApi.useDictChildren({ dictType: 'sys_normal_disable' })
const { data: roleSortResult } = AdminApi.useDictChildren({ dictType: 'sys_role_sort' })
const { data: deptTreeResult, execute: deptTreeModel } = AdminApi.useModuleApi(computed(() => `/system/role/deptTree/${dialogForm.value.roleId}`), {}, { immediate: false }).get().json()
const { data: getRoleResult, execute: getRoleModel } = AdminApi.useModuleApi(computed(() => `/system/role/${dialogForm.value.roleId}`), {}, { immediate: false }).get().json<Result<RoleModel>>()
function optionsGenerator(dict: any) {
  const _: any[] = []
  dict.value?.rows.forEach((item: any) => {
    const option = {
      label: parseMultilingual(item.dictLabel),
      value: item.dictValue,
    }
    _.push(option)
  },
  )
  return _
}
const roleStatusOptions = computed(() => optionsGenerator(dictResult))
const dataScopeOptions = computed(() => optionsGenerator(roleSortResult))
watch([addResult, updateResult, changeRoleStatusResult, changeRoleStatusError], () => {
  const data = addResult.value || updateResult.value || changeRoleStatusResult.value
  if (data) {
    showDialog.value = false
    // eslint-disable-next-line ts/no-use-before-define
    reload()
  }
  if (deleteResult.value?.code === 200) {
    // eslint-disable-next-line ts/no-use-before-define
    reload()
    message.success(t('successfullyDeleted'))
  }
})
watch(getRoleResult, () => {
  if (getRoleResult.value) {
    reset()
    dialogForm.value = getRoleResult.value.data as any
  }
})
watch(deptTreeResult, () => {
  if (deptTreeResult.value) {
    deptChecked.value = deptTreeResult.value.checkedKeys
    dataTreeDate.value = deptTreeResult.value.depts
    dialogForm.value.deptIds = deptTreeResult.value.checkedKeys
    openDataScope.value = true
  }
})
watch(menuCheckResult, () => {
  if (menuCheckResult.value) {
    menuChecked.value = menuCheckResult.value.checkedKeys
    menuTreeData.value = menuCheckResult.value.menus
    dialogForm.value.menuIds = menuCheckResult.value.checkedKeys
    showDialog.value = true
  }
})
watch(menuResult, () => {
  menuTreeData.value = menuResult.value.data
})
watch(updateDaraScopeResult, () => {
  openDataScope.value = false
})
function reset() {
  dialogForm.value = {
    roleId: null,
    roleName: '',
    roleKey: '',
    roleSort: 0,
    status: '0',
    menuIds: [],
    deptIds: [],
    menuCheckStrictly: true,
    deptCheckStrictly: true,
    remark: '',
    dataScope: '',
    dataPermissions: '',
    menuPermissions: '',
  }
}
function handleAdd() {
  reset()
  menuModel()
  menuChecked.value = []
  title.value = t('add')
  showDialog.value = true
}
function handleUpdate(row: any) {
  title.value = t('modify')
  reset()
  dialogForm.value = row
  checkStrictly.value = row.menuCheckStrictly
  menuCheckModel()
}
function handleDataScope(row: any) {
  reset()
  dialogForm.value = row
  checkStrictly.value = row.deptCheckStrictly
  deptTreeModel()
  getRoleModel()
}
function handleAuthUser(row: any) {
  router.replace({ name: 'RoleAuth', params: { roleId: row.roleId } })
}
function submitForm() {
  formRef.value?.validate((errors) => {
    if (errors)
      return
    if (dialogForm.value.roleId)
      updateModel()
    else
      addModel()
  })
}
function submitFormDataScope() {
  dialogForm.value.deptIds = dialogForm.value.deptIds ?? []
  updateDataScopeModel()
}
function cancel() {
  showDialog.value = false
  openDataScope.value = false
}
function handleDelete(row: any) {
  dialog.warning({
    title: t('deleteHint'),
    content: t('deleteReminder'),
    positiveText: t('confirm'),
    negativeText: t('cancel'),
    onPositiveClick: () => {
      dialogForm.value = row
      deleteModel()
    },
  })
}
const _columns = computed(() => i18nColumns<RoleModel>(t, 'system.role', ['roleId', 'roleName', 'roleKey', 'roleSort', 'status', 'createTime', 'actions'], (key, column) => {
  column.align = 'center'
  if (key === 'status') {
    return {
      ...column,
      render(rowData, _rowIndex) {
        return (
          <NSwitch
            value={!Number.parseInt(rowData.status)}
            onUpdateValue={(value) => {
              changeRoleStatusForm.value.roleId = (rowData.roleId) as number
              changeRoleStatusForm.value.status = value ? '0' : '1'
              // eslint-disable-next-line ts/no-use-before-define
              changeRoleStatus().then(() => reload())
            }}
          />
        )
      },
    }
  }
  if (key === 'roleName') {
    return {
      ...column,
      render(rowData, _rowIndex) {
        return <span>{parseMultilingual(rowData.roleName)}</span>
      },
    }
  }
  if (key === 'createTime') {
    return {
      ...column,
      render(rowData, _rowIndex) {
        return <NTime time={new Date(rowData.createTime)}></NTime>
      },
    }
  }
  if (key === 'actions') {
    return {
      ...column,
      render(rowData, _rowIndex) {
        if (rowData.roleId === 1) {
          return <span></span>
        }
        else {
          return (
            <n-button-group v-if="rowData.userId !== 1">
              <NButton
                circle
                size="tiny"
                class="mb-1"
                onClick={() => {
                  handleUpdate(JSON.parse(JSON.stringify(rowData)))
                }}
                renderIcon={() => <span class="i-carbon-edit" />}
              >
              </NButton>
              <NButton
                size="tiny"
                onClick={() => {
                  handleDelete(rowData)
                }}
                renderIcon={() => <span class="i-carbon-trash-can" />}
              >
              </NButton>
              <NButton
                size="tiny"
                onClick={() => {
                  handleDataScope(rowData)
                }}
                renderIcon={() => <span class="i-carbon-checkmark-outline" />}
              >
              </NButton>
              <NButton
                size="tiny"
                onClick={() => {
                  handleAuthUser(rowData)
                }}
                renderIcon={() => <span class="i-carbon-user" />}
              >
              </NButton>
            </n-button-group>
          )
        }
      },
    }
  }
  return column
}))
const { tableData, columns, pagination, selectHandle, reload, isFetching } = useModuleDataTable<RoleModel>('system/role', _columns, queryParams)
function refresh() {
  reload()
}
function handleMenuSelect(value: any) {
  dialogForm.value.menuIds = value ?? []
}
function handleDataScopeSelect(value: any) {
  dialogForm.value.deptIds = value ?? []
}
</script>

<template>
  <n-form
    inline :model="queryParams" label-align="left" label-placement="left" :label-width="140"
    require-mark-placement="right-hanging" flex-wrap
  >
    <n-form-item :label="t('columns.system.role.roleName')" path="roleName">
      <n-input v-model:value="queryParams.roleName" clearable />
    </n-form-item>
    <n-form-item :label="t('columns.system.role.roleKey')" path="roleKey">
      <n-input v-model:value="queryParams.roleKey" clearable />
    </n-form-item>
    <n-form-item :label="t('columns.system.role.status')" path="status">
      <n-select v-model:value="queryParams.status" w-45 :options="roleStatusOptions" clearable />
    </n-form-item>
  </n-form>
  <div mb-5>
    <NButton type="primary" :disabled="addIsFetching || updateIsFetching" @click="handleAdd">
      <template #icon>
        <span i-carbon-add />
      </template>{{ t('add') }}
    </NButton>
    <NButton circle style="float: right;" @click="refresh">
      <template #icon>
        <span i-carbon-reset />
      </template>
    </NButton>
  </div>
  <n-data-table
    v-model:page="pagination.page" v-model:page-size="pagination.pageSize" remote :data="tableData"
    :columns="columns" :pagination="pagination" :row-key="(row:RoleModel) => (row.roleId) as number" :loading="roleStatusChanging || isFetching || addIsFetching || deleteIsFetching || deleteIsFetching"
    @update:checked-row-keys="selectHandle"
  />
  <n-modal v-model:show="showDialog" :mask-closable="false" preset="dialog" :title="title" :show-icon="false">
    <n-form ref="formRef" :rules="rules" :model="dialogForm" label-placement="left" :label-width="110" label-align="left">
      <n-form-item :label="t('columns.system.role.roleName')" path="roleName">
        <n-popover trigger="click" placement="bottom">
          <template #trigger>
            <n-input :value="parseMultilingual(dialogForm.roleName)" readonly />
          </template>
          <template #default>
            <locale-input v-model="dialogForm.roleName" style="width: 100%;min-width: 20rem;max-width: 35rem;" />
          </template>
        </n-popover>
      </n-form-item>
      <n-form-item :label="t('columns.system.role.roleKey')" path="roleKey">
        <n-input v-model:value="dialogForm.roleKey" clearable />
      </n-form-item>
      <n-form-item :label="t('columns.system.role.roleSort')" path="roleSort">
        <n-input-number v-model:value="dialogForm.roleSort" clearable />
      </n-form-item>
      <n-form-item :label="t('columns.system.role.status')" path="status">
        <n-radio-group v-model:value="dialogForm.status" name="radiogroup">
          <n-radio v-for="option in roleStatusOptions" :key="option.label" :value="option.value">
            {{ option.label }}
          </n-radio>
        </n-radio-group>
      </n-form-item>
      <n-form-item :label="t('columns.system.role.menuPermissions')" path="menuIds">
        <div class="w-100%">
          <n-checkbox v-model:checked="checkStrictly">
            {{ t('columns.system.role.checkStrictly') }}
          </n-checkbox>
          <n-tree
            multiple
            :cascade="checkStrictly"
            checkable clearable :data="menuTreeData" key-field="id" :default-checked-keys="menuChecked"
            :render-label="({ option }) => h('span', parseMultilingual(option.label))" @update:checked-keys="handleMenuSelect"
          />
        </div>
      </n-form-item>
      <n-form-item :label="t('remark')" path="remark">
        <n-input v-model:value="dialogForm.remark" clearable />
      </n-form-item>
    </n-form>
    <template #action>
      <NButton ghost @click="cancel">
        {{ t('cancel') }}
      </NButton>
      <NButton strong type="primary" @click="submitForm">
        {{ t('confirm') }}
      </NButton>
    </template>
  </n-modal>
  <n-modal v-model:show="openDataScope" preset="dialog" :title="title" :show-icon="false">
    <n-form :model="dialogForm" label-placement="left" :label-width="110" label-align="left">
      <n-form-item :label="t('columns.system.role.roleName')" path="roleName">
        <n-popover trigger="click" placement="bottom">
          <template #trigger>
            <n-input :value="parseMultilingual(dialogForm.roleName)" readonly />
          </template>
          <template #default>
            <locale-input v-model="dialogForm.roleName" style="width: 100%;min-width: 25rem;max-width: 40rem;" />
          </template>
        </n-popover>
      </n-form-item>
      <n-form-item :label="t('columns.system.role.roleKey')" path="roleKey">
        <n-input v-model:value="dialogForm.roleKey" clearable />
      </n-form-item>
      <n-form-item :label="t('columns.system.role.dataScope')" path="dataScope">
        <n-select v-model:value="dialogForm.dataScope" clearable :options="dataScopeOptions" />
      </n-form-item>
      <n-form-item v-show="dialogForm.dataScope === '2'" :label="t('columns.system.role.dataPermissions')" path="dataPermissions">
        <n-checkbox v-model:checked="checkStrictly">
          {{ t('columns.system.role.checkStrictly') }}
        </n-checkbox>
        <n-tree
          multiple
          :cascade="checkStrictly"
          :default-checked-keys="deptChecked" checkable clearable :data="dataTreeDate" key-field="id"
          :render-label="({ option }) => h('span', parseMultilingual(option.label))" default-expand-all @update-checked-keys="handleDataScopeSelect"
        />
      </n-form-item>
    </n-form>
    <template #action>
      <NButton ghost @click="cancel">
        {{ t('cancel') }}
      </NButton>
      <NButton strong type="primary" :disabled="addIsFetching || updateIsFetching" @click="submitFormDataScope">
        {{ t('confirm') }}
      </NButton>
    </template>
  </n-modal>
</template>

<style scoped></style>
