<script setup lang="tsx">
import { AdminApi, parseMultilingual } from '@eggor/vue-shared'
import type { IdentityModel } from '@eggor/vue-shared'
import type { FormInst, FormRules } from 'naive-ui'
import { NButton, NButtonGroup, NTime } from 'naive-ui'
import DictTagClass from '~/components/core/DictTagClass.vue'

definePage({
  name: 'roleIdentify',
})
const { t } = useI18n()
const title = ref('')
const message = useMessage()
const formRef = ref<FormInst | null>(null)
const showDialog = ref<boolean>(false)
const showPreview = ref<boolean>(false)
const queryParams = reactive({
  userId: null,
  applyIdentity: null,
  tel: null,
  email: null,
  country: null,
  address: null,
  bio: null,
  productionUrl: null,
  ownerUrl: null,
  personalUrl: null,
  photo: null,
  reason: null,
  status: '',
})
const dialogForm = ref({
  id: undefined,
  reason: undefined,
  status: undefined,
  userId: undefined,
})
const previewRow = ref({
  id: '',
  userId: '',
  applyIdentity: null,
  userName: '',
  tel: '',
  email: '',
  country: '',
  address: '',
  bio: '',
  artworkFiles: [],
  ownershipFiles: [],
  externalLinks: [],
  personalLinks: [],
  personalPhotos: [],
  reason: '',
  status: null,
})
const rules: FormRules = {

  status: {
    required: true,
    message: t('rules.message'),
    trigger: ['blur'],
  },
}
const { data: checkOverResult, isFetching: checkOvering, execute: checkOverModel } = AdminApi.useAddModel('eggor/user/checkOver', dialogForm)
const { data: roleIdentityResult } = AdminApi.useDictChildren({ dictType: 'sys_role_identity' })
const { data: identityStatusResult } = AdminApi.useDictChildren({ dictType: 'sys_identity_status' })

function optionsGenerator(dict: any) {
  const _: any[] = []
  dict.value?.rows.forEach((item: any) => {
    const option = {
      label: parseMultilingual(item.dictLabel),
      value: item.dictValue,
    }
    _.push(option)
  },
  )
  return _
}
const roleIdentityOptions = computed(() => optionsGenerator(roleIdentityResult))
const identityStatusOptions = computed(() => {
  const _ = optionsGenerator(identityStatusResult)
  _.shift()
  return _
})
const isPassOptions = computed(() => {
  const _ = identityStatusOptions.value
  _.shift()
  return _
})
function reset() {
  dialogForm.value = {
    id: undefined,
    reason: undefined,
    status: undefined,
    userId: undefined,
  }
}
function submitForm() {
  formRef.value?.validate((errors) => {
    if (errors)
      return
    checkOverModel()
  })
}
function cancel() {
  showDialog.value = false
  showPreview.value = false
}
function handleView(row: any) {
  title.value = t('preview')
  showPreview.value = true
  previewRow.value = row
}

function handleCheckOver(row: any) {
  reset()
  title.value = t('identify')
  dialogForm.value.id = row.id
  dialogForm.value.reason = row.reason
  dialogForm.value.status = row.status
  dialogForm.value.userId = row.userId
  showDialog.value = true
}
watch([checkOverResult], () => {
  const data = checkOverResult
  if (data) {
    message.success(t('successfullyModified'))
    showDialog.value = false
    // eslint-disable-next-line ts/no-use-before-define
    reload()
  }
})

const _columns = computed(() => i18nColumns<IdentityModel>(t, 'eggor.identity', ['id', 'userId', 'applyIdentity', 'tel', 'email', 'country', 'address', 'bio', 'reason', 'status', 'createTime', 'actions'], (key, column) => {
  column.width = '80px'
  column.align = 'center'
  if (key === 'id') {
    return {
      ...column,
      width: '50px',
    }
  }
  if (key === 'applyIdentity') {
    return {
      ...column,
      render(rowData, _rowIndex) {
        return <DictTagClass row={roleIdentityResult.value?.rows.find(item => item.dictValue === (rowData.applyIdentity.toString()))}></DictTagClass>
      },
    }
  }
  if (key === 'status') {
    return {
      ...column,
      render(rowData, _rowIndex) {
        return <DictTagClass row={identityStatusResult.value?.rows.find(item => item.dictValue === (rowData.status.toString()))}></DictTagClass>
      },
    }
  }
  if (key === 'createTime') {
    return {
      ...column,
      render(rowData, _rowIndex) {
        return <NTime time={new Date(rowData.createTime)}></NTime>
      },
    }
  }
  if (key === 'bio') {
    return {
      ...column,
      render(rowData, _rowIndex) {
        return <p class="line-clamp-4">{ rowData.bio}</p>
      },
    }
  }
  if (key === 'actions') {
    return {
      ...column,
      render(rowData, _rowIndex) {
        return (
          <NButtonGroup size="small">
            { ['0', '1'].includes(rowData.status.toString()) && (
              <NButton
                size="small"
                onClick={() => {
                  handleCheckOver(rowData)
                }}
                renderIcon={() => <span class="i-carbon-star-review" />}
              />
            ) }
            <NButton
              size="small"
              onClick={() => {
                handleView(rowData)
              }}
              renderIcon={() => <span class="i-carbon-view" />}
            >
            </NButton>
          </NButtonGroup>
        )
      },
    }
  }
  return column
}))
const { tableData, columns, pagination, selectHandle, rowKey, reload, isFetching } = useModuleDataTable<IdentityModel>('system/identity', _columns, queryParams)
function refresh() {
  reload()
}
</script>

<template>
  <n-form
    label-align="left"
    inline
    :model="queryParams"
    label-placement="left"
    :label-width="140"
    require-mark-placement="right-hanging"
    flex-wrap
  >
    <n-form-item :label="t('columns.eggor.identity.userId')" path="userId">
      <n-input v-model:value="queryParams.userId" clearable />
    </n-form-item>
    <n-form-item :label="t('columns.eggor.identity.email')" path="email">
      <n-input v-model:value="queryParams.email" clearable />
    </n-form-item>
    <n-form-item :label="t('columns.eggor.identity.applyIdentity')" path="applyIdentity">
      <n-select v-model:value="queryParams.applyIdentity" :options="roleIdentityOptions" clearable w-48 />
    </n-form-item>
    <n-form-item :label="t('columns.eggor.identity.status')" path="status">
      <n-select v-model:value="queryParams.status" :options="identityStatusOptions" clearable w-48 />
    </n-form-item>
  </n-form>
  <div mb-5>
    <NButton circle style="float: right;" @click="refresh">
      <template #icon>
        <span i-carbon-reset />
      </template>
    </NButton>
  </div>
  <n-data-table
    v-model:page="pagination.page" v-model:page-size="pagination.pageSize" remote :data="tableData"
    :columns="columns" :pagination="pagination" :row-key="rowKey" :loading="isFetching || checkOvering" @update:checked-row-keys="selectHandle"
  />
  <n-modal v-model:show="showDialog" :mask-closable="false" preset="dialog" :title="title" :show-icon="false" :style="{ width: '30%', maxWidth: '40rem', minWidth: '25rem' }">
    <n-form
      ref="formRef"
      :model="dialogForm"
      label-placement="left"
      :label-width="110"
      label-align="left"
      :rules="rules"
    >
      <n-form-item :label="t('columns.eggor.identity.status')" path="status">
        <n-radio-group v-model:value="dialogForm.status" name="radiogroup">
          <n-radio v-for="option in isPassOptions" :key="option.label" :value="option.value">
            {{ option.label }}
          </n-radio>
        </n-radio-group>
      </n-form-item>
      <n-form-item v-show="dialogForm.status === '2'" :label="t('columns.eggor.identity.reason')" path="reason">
        <n-input v-model:value="dialogForm.reason" clearable />
      </n-form-item>
    </n-form>
    <template #action>
      <NButton ghost :loading="checkOvering" @click="cancel">
        {{ t('cancel') }}
      </NButton>
      <NButton strong type="primary" :disabled="checkOvering" @click="submitForm">
        {{ t('confirm') }}
      </NButton>
    </template>
  </n-modal>
  <n-modal v-model:show="showPreview" preset="dialog" :title="title" :show-icon="false" :style="{ width: '45%', maxWidth: '50rem', minWidth: '40rem' }">
    <n-form :model="previewRow" label-placement="left" :label-width="120" label-align="left">
      <n-grid :cols="24" :x-gap="24">
        <n-form-item-gi :label="t('columns.eggor.identity.userId')" :span="12" label-style="opacity: 70%">
          {{ previewRow.userId }}
        </n-form-item-gi>
      </n-grid>
      <n-grid :cols="24" :x-gap="24">
        <n-form-item-gi :label="t('columns.eggor.identity.applyIdentity')" :span="12" label-style="opacity: 70%">
          <DictTagClass :row="roleIdentityResult?.rows.find(item => item.dictValue === (previewRow.applyIdentity ?? '').toString())" />
        </n-form-item-gi>
        <n-form-item-gi :label="t('columns.eggor.identity.userName')" :span="12" label-style="opacity: 70%">
          {{ previewRow.userName }}
        </n-form-item-gi>
      </n-grid>
      <n-grid :cols="24" :x-gap="24">
        <n-form-item-gi :label="t('columns.eggor.identity.country')" :span="12" label-style="opacity: 70%">
          {{ previewRow.country }}
        </n-form-item-gi>
        <n-form-item-gi :label="t('columns.eggor.identity.email')" :span="12" label-style="opacity: 70%">
          {{ previewRow.email }}
        </n-form-item-gi>
      </n-grid>
      <n-grid :cols="24" :x-gap="24">
        <n-form-item-gi :label="t('columns.eggor.identity.tel')" :span="12" label-style="opacity: 70%">
          {{ previewRow.tel }}
        </n-form-item-gi>
        <n-form-item-gi :label="t('columns.eggor.identity.bio')" :span="12" label-style="opacity: 70%">
          {{ previewRow.bio }}
        </n-form-item-gi>
      </n-grid>
      <n-grid :cols="24" :x-gap="24">
        <n-form-item-gi :label="t('columns.eggor.identity.address')" :span="12" label-style="opacity: 70%">
          {{ previewRow.address }}
        </n-form-item-gi>
        <n-form-item-gi :label="t('columns.eggor.identity.reason')" :span="12" label-style="opacity: 70%">
          {{ previewRow.reason }}
        </n-form-item-gi>
      </n-grid>
      <n-form-item :label="t('columns.eggor.identity.artworkFiles')" label-style="opacity: 70%">
        <div class="col gap-.5rem">
          <a v-for="file in previewRow.artworkFiles" :key="file" :href="file" target="_blank">{{ file }}</a>
        </div>
      </n-form-item>
      <n-form-item :label="t('columns.eggor.identity.ownershipFiles')" label-style="opacity: 70%">
        <div class="col gap-.5rem">
          <a v-for="file in previewRow.ownershipFiles" :key="file" :href="file" target="_blank">{{ file }}</a>
        </div>
      </n-form-item>
      <n-form-item :label="t('columns.eggor.identity.externalLinks')" label-style="opacity: 70%">
        <div class="col gap-.5rem">
          <a v-for="file in previewRow.externalLinks" :key="file" :href="file" target="_blank">{{ file }}</a>
        </div>
      </n-form-item>
      <n-form-item :label="t('columns.eggor.identity.personalLinks')" label-style="opacity: 70%">
        <div class="col gap-.5rem">
          <a v-for="file in previewRow.personalLinks" :key="file" :href="file" target="_blank">{{ file }}</a>
        </div>
      </n-form-item>
      <n-form-item :label="t('columns.eggor.identity.personalPhotos')" label-style="opacity: 70%">
        <n-image-group>
          <n-space>
            <n-image
              v-for="file in previewRow.personalPhotos"
              :key="file" width="100" :href="file"
              :src="file"
            />
          </n-space>
        </n-image-group>
      </n-form-item>
    </n-form>
    <template #action>
      <NButton ghost @click="cancel">
        {{ t('close') }}
      </NButton>
    </template>
  </n-modal>
</template>

<style scoped></style>
